import React from "react";
import Section from "./../../atoms/Section/Section";

const LaufendeKurseSection = props => {
  return (
    <Section theme={props.theme || "primary"}>
      <h3>Laufende Kurse</h3>
      <p>
        Alle Kurse starten zweimal im Jahr neu! Anmeldungen werden gerne
        entgegengenommen. Bei freien Plätzen ist ein Quereinstieg jederzeit
        möglich. Bitte informieren Sie sich HIER über die aktuellen Kurstermine
        bzw. in unserem Büro unter 06051/75108 oder nutzen Sie unser
        Kontaktformular
      </p>
    </Section>
  );
};

export default LaufendeKurseSection;
