import React from "react";
import styled from "styled-components";
import { variables } from "../../../data/variables";
import Section from "./../Section/Section";

const HeroWrapper = styled.div`
  width: 100%;
  ${(props) =>
    !props.small && `height: calc(100vh - ${variables.sizes.navbar});`}
  overflow: hidden;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: left top;
  background-repeat: none none;
  position: relative;
`;

const Content = styled.div`
  position: absolute;
  right: 10%;
  top: 50%;
  left: 5%;
  text-align: right;
  font-size: 1.5rem;
  word-break: break-word;
  font-weight: bold;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.7);
  color: ${variables.text.primary};
  @media (min-width: ${variables.breakpoints.s}px) {
    font-size: 2rem;
  }
  @media (min-width: ${variables.breakpoints.m}px) {
    font-size: 3rem;
  }
`;

const ContentSmall = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.7);
  color: ${variables.text.primary};
  @media (min-width: ${variables.breakpoints.s}px) {
    font-size: 2rem;
  }
  @media (min-width: ${variables.breakpoints.m}px) {
    font-size: 3rem;
  }
`;

export const Heading = styled.h1`
  font-size: 1.5rem;
  text-align: center;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  &::after {
    content: "";
    margin-top: 1rem;
    width: 15rem;
    max-width: 50vw;
    border-bottom: 2px solid ${variables.background.secondaryLight};
  }
`;

const Hero = (props) => {
  if (!props.image) {
    return (
      <Section style={{}}>
        <Heading>{props.children}</Heading>
      </Section>
    );
  }

  return (
    <HeroWrapper image={props.image} small={props.small} style={props.style}>
      {props.small ? (
        <Section>
          <ContentSmall style={props.style} small={props.small}>
            {props.children}
          </ContentSmall>
        </Section>
      ) : (
        <Content style={props.style} small={props.small}>
          {props.children}
        </Content>
      )}
    </HeroWrapper>
  );
};

export default Hero;
