import React from "react";
import Section from "../../atoms/Section/Section";
import Tabelle from "../../molecules/Tabelle/Tabelle";
import { variables } from "../../../data/variables";
import styled from "styled-components";

const SubTitle = styled.div`
  font-size: 1.5rem;
  text-transform: uppercase;
  @media (min-width: ${variables.breakpoints.m}px) {
    font-size: 2rem;
  }
`;

const Title = styled.div`
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: bold;
  @media (min-width: ${variables.breakpoints.m}px) {
    font-size: 3rem;
  }
`;

const Footer = styled.div`
  font-size: 0.8rem;
`;

const KursTabelle = props => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Section
        theme="primary"
        lineBottom
        style={{ maxWidth: 1400, width: "100%" }}
      >
        <SubTitle
          contentEditable={props.edit}
          suppressContentEditableWarning={props.edit}
        >
          {props.subtitle}
        </SubTitle>
        <Title
          contentEditable={props.edit}
          suppressContentEditableWarning={props.edit}
        >
          {props.title}
        </Title>
      </Section>
      <Section style={{ width: "100%" }}>
        {props.data &&
          props.data.map(table => {
            return (
              <Tabelle
                edit={props.edit}
                key={table.title}
                title={table.title}
                color={table.color}
                header={table.header}
                drop={table.drop}
                columnCount={table.columnCount}
                rows={table.rows}
              />
            );
          })}
        <Footer>{props.footer}</Footer>
      </Section>
    </div>
  );
};

export default KursTabelle;
