import React from "react";
import styled from "styled-components";
import { variables } from "./../../../data/variables";

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem 0;
  overflow: hidden;
  flex-direction: column;
  @media (min-width: ${variables.breakpoints.m}px) {
    ${props =>
      props.reverse ? "flex-direction:row-reverse;" : "flex-direction:row;"}
  }
`;

const Picture = styled.img`
  @media (min-width: ${variables.breakpoints.m}px) {
    padding: 2rem;
    height: 25rem;
    flex: 1;
  }
  height: 60vw;
  object-fit: cover;
  overflow: hidden;
`;

const Name = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
`;

const Description = styled.div``;

const Content = styled.div`
  padding: 2rem 0;
  @media (min-width: ${variables.breakpoints.m}px) {
    padding: 2rem;
    ${props => props.reverse && "text-align:right;"}
  }
  flex: 1;
`;

const BandBlock = props => {
  return (
    <Container reverse={props.reverse}>
      <Picture src={props.img} />
      <Content reverse={props.reverse}>
        <Name>{props.name}</Name>
        <Description>{props.children}</Description>
      </Content>
    </Container>
  );
};

export default BandBlock;
