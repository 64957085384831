import React from "react";
import Hero from "../../atoms/Hero/Hero";
import Section from "../../atoms/Section/Section";
import SocialMediaBar from "../../molecules/SocialMediaBar/SocialMediaBar";
import Footer from "../../organisms/Footer/Footer";

const UberUnsPage = () => {
  return (
    <div>
      <Hero>Über Uns</Hero>
      <Section>
        <h4>Musik Total: Willkommen in einer großen Familie!</h4>
        <p>
          "Hier fühl ich mich daheim!" - das hören wir immer wieder. Von unseren
          kleinen und großen Schülern, von unseren Dozenten, von Gästen. Und das
          ist uns auch ganz wichtig! Musik Total ist eine Musikschule, aber auch
          eine große Familie. Wir legen viel Wert auf eine persönliche,
          freundschaftliche Atmosphäre. Wer zu uns kommt, soll etwas lernen,
          aber sich auch in seiner Persönlichkeit weiterentwickeln können. Spaß
          haben. An der Musik und mit der Musik wachsen. Wir wollen euch Raum
          geben für eure eigenen Ideen und Bedürfnisse und haben uns deshalb
          auch für ein ganzheitliches Unterrichtskonzept entschieden. Eines, das
          die unterschiedlichen Lerntypen berücksichtigt, die unterschiedlichen
          Begabungen und Fähigkeiten. Und eines, bei dem zwischendurch herzhaft
          gelacht werden darf!
        </p>
        <h4>Gemeinsam wachsen!</h4>
        <p>
          {" "}
          Wir, Silke und Andi Knoll, sind beide Diplom-Sozialpädagogen und haben
          Musik Total 1996 gegründet. Mit einem einzigen Unterrichtsraum fing es
          an! Und dann ging es Stück für Stück weiter. Neue Räume kamen hinzu,
          immer mehr Schüler, viele neue Ideen - und auch zwei Söhne. Nicolai
          und Julian, die mittlerweile ebenfalls begeisterte Musiker sind. Und
          viel Spaß am Unterrichten haben. Einer unserer Schwerpunkte liegt in
          der musikalischen Betreuung von sehr kleinen Musikfreunden. Für die
          Windel- und Sandkastenrocker sind wir nicht nur in unseren Kursen da,
          sondern schreiben für sie auch Kinderlieder und lassen uns auch sonst
          allerhand einfallen. Mehr dazu findet ihr unter "Nieke und die
          Tonartisten".{" "}
        </p>
        <h4>Live, live, live - hier könnt ihr Musik erleben!</h4>
        <p>
          {" "}
          Bei Musik Total ist alles ein bisschen anders. Unsere Dozenten sind -
          das ist noch nicht so ungewöhnlich - erfahrene Musikpädagogen. Aber:
          Sie sind fast alle auch aktive Live-Musiker. Sie leben Musik, nicht
          nur in der Theorie, sondern auch ganz praktisch. Sie stehen mit ihren
          eigenen Bands oder als Solokünstler auf der Bühne - und können euch
          deshalb umso besser genau das vermitteln, was über das Beherrschen
          eines Instruments hinausgeht: sich trauen, vor Menschen zu spielen.
          Show und Action auf der Bühne machen. Mit anderen zusammen spielen,
          und nicht nur allein für sich im stillen Kämmerlein. Davon profitieren
          unsere Schüler enorm. Sie lernen von der Pike auf, worauf es ankommt.
          Und werden schnell selbständig - zum Beispiel, wenn es darum geht,
          sich auf eigene Faust ein Stück zu erarbeiten. Und was uns besonders
          freut: Einige unserer Dozenten waren früher selbst Schüler bei Musik
          Total. Toll, dass sie immer noch in der Familie sind - und jetzt ihr
          Wissen an euch weitergeben.
        </p>
        <h4>
          {" "}
          Viel Raum für Kreativiät und mehr - Musik Total in Höchst und Bad
          Soden-Salmünster Musik Total bietet euch in Gelnhausen-Höchst und Bad
          Soden-Salmünster
        </h4>
        <p>
          {" "}
          jede Menge Platz für euren Musikunterricht. Alle Räume sind sehr hell
          und freundlich eingerichtet und berücksichtigen auch die besonderen
          Bedürfnisse von Allergikern. Teppichboden sucht ihr bei uns
          vergeblich! Zudem verfügen wir über gut ausgestattete Tagungsräume,
          die wir gerne auch für Meetings und Seminare vermieten. Zum Beispiel
          an Vereine oder Firmen. Ein Anruf genügt.
        </p>
      </Section>
      <SocialMediaBar />
      <Footer />
    </div>
  );
};

export default UberUnsPage;
