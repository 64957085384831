import React from "react";
import HeaderImg from "../../../static/img/musiktherapie/header.jpg";
import Stock1 from "../../../static/img/musiktherapie/stock1.jpg";
import Stock2 from "../../../static/img/musiktherapie/stock2.jpg";
import Stock3 from "../../../static/img/musiktherapie/stock3.jpg";
import Stock4 from "../../../static/img/musiktherapie/stock4.jpg";
import bg from "../../../static/img/musiktherapie/bg.jpg";
import logoImg from "../../../static/img/musiktherapie/logo.png";
import Hero from "./../../atoms/Hero/Hero";
import Section from "../../atoms/Section/Section";
import styled from "styled-components";
import SocialMediaBar from "./../../molecules/SocialMediaBar/SocialMediaBar";
import Footer from "./../../organisms/Footer/Footer";
import { variables } from "../../../data/variables";

const Polaroid = styled.div`
  border: 1rem solid white;
  box-shadow: 0px 3px 3px rgba(0,0,0,.2);
  height: 20rem;
  width:20rem;
  margin:1rem;
  background-image: url("${props => props.img}");
  background-size:cover;
  background-position: center top;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  grid-gap: 1rem 1rem;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`;

const Logo = styled.img`
  width: 90%;
  z-index: 1;

  @media (min-width: ${variables.breakpoints.m}px) {
    padding-bottom: 20vh;
  }
`;

const LogoWrapper = styled.div`
  margin-left: auto;
  display: flex;
  position: relative;
  width: 25vw;
  align-items: flex-end;
  justify-content: center;
  height: 100vh;
  background-color: rgba(255, 240, 240, 0.7);
  border-left: 3px dashed white;
  border-right: 3px dashed white;
  overflow: hidden;

  @media (max-width: ${variables.breakpoints.m}px) {
    align-items: center;
    width: 80vw;
  }
`;

const Headerbg = styled.img`
    position: absolute;
    bottom: 0;
    opacity: 0.4;
    width: 100%;
    height: 100vh;
    z-index: 0;
    object-fit: cover;
    background-image:url("${bg}");
`;

const MusikTherapie = () => {
  return (
    <div>
      <Hero image={HeaderImg} style={{ top: 0 }}>
        <LogoWrapper>
          <Logo src={logoImg} />
          <Headerbg src={bg} />
        </LogoWrapper>
      </Hero>
      <div
        style={{
          backgroundImage: `url("${bg}")`,
          backgroundSize: "cover",
          backgroundPosition: "center bottom",
          backgroundRepeat: "no-repeat no-repeat"
        }}
      >
        <Section>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
              <Polaroid img={Stock1} />
            </div>
            <div style={{ flex: 1 }}>
              <h1 style={{ margin: 0, fontStyle: "Italic" }}>
                „Die Musik spricht für sich allein. Vorausgesetzt, wir geben ihr
                eine Chance."
              </h1>
              <p>
                Gefühle bestimmen unser körperliches und seelisches
                Wohlbefinden. Wenn wir unsere Gefühle nicht zulassen,
                verschaffen sie sich Gehör auf unterschiedliche Art und Weise
                und bringen somit unser Leben aus dem Gleichgewicht.
              </p>
              <p>
                Unser Alltag in Kindergarten, Schule, Familie, Vereinsleben und
                im Job gibt häufig zu wenig Raum, um Empfindungen zu leben und
                Gefühlen ihren Raum zu lassen. Es können Worte der Resignation
                entstehen wie z. B. „Ich fühle mich nicht gesehen“, „Niemand
                versteht mich“, „ Ich bin immer der Dumme!“.Ich möchte Sie gerne
                in Ihrer Gefühlswelt begleiten, um alte „belastende“
                Verhaltensmuster zu durchbrechen und den „Wert Ihrer selbst“
                oder den Ihres Kindes neu schätzen zu lernen.{" "}
              </p>
              <p>
                In der Musiktherapie wird durch freies Improvisieren an
                einfachen Instrumenten und mit unserer Stimme den innersten
                Gefühlen und Empfindungen Ausdruck verliehen. Wir können dem
                Klang unserer Gefühle zuhören.{" "}
              </p>
              <p>
                Die Musik bietet eine wundervolle, spielerische Möglichkeit,
                sich auszudrücken - denn wo Worte fehlen, können Klänge
                sprechen!
              </p>
            </div>
          </div>
        </Section>
        <Section>
          <Grid>
            <Polaroid img={Stock2} />
            <Polaroid img={Stock3} />
            <Polaroid img={Stock4} />
          </Grid>
        </Section>
      </div>
      <SocialMediaBar />
      <Footer />
    </div>
  );
};

export default MusikTherapie;
