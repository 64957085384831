import React from "react";
import Hero from "../../atoms/Hero/Hero";
import Section from "../../atoms/Section/Section";
import Footer from "../../organisms/Footer/Footer";
import SocialMediaBar from "../../molecules/SocialMediaBar/SocialMediaBar";
import ZwergenRockerImg from "../../../static/img/zwergenrocker.jpg";
import PicTextBlock from "./../../molecules/PicTextBlock/PicTextBlock";
import LaufendeKurseSection from "./../../molecules/LaufendeKurseSection/LaufendeKurseSection";
import KursTemplate from "./../../templates/KursTemplate/KursTemplate";

const ZwergenRocker = () => {
  return (
    <KursTemplate
      title="Die Zwergenrocker"
      polaroid={ZwergenRockerImg}
      age="Alter: 1 1/2 - 3 Jahre"
    >
      <p>
        Die Neugierde wächst in diesem Alter, der Erlebnisraum wird größer und
        größer. Bei den Zwergenrockern greifen wir z.B. Themen aus der Tier-,
        der Umwelt oder etwa zu Hause auf. Wir tanzen, musizieren, singen und
        imitieren. Wir fördern hierbei spielerisch das Sprech- und Hörverhalten
        und regen zum musizieren an. Reinkommen, staunen und schauen, was bei
        den Zwergen schon alles geht!.
      </p>
      <p>
        Wir nutzen traditionelles wie aktuelles Liedgut, bereichert durch unsere
        eigenen Kinderlieder von „Nieke und die Tonartisten“, die in unserer
        Musikschule entstehen.
      </p>
    </KursTemplate>
  );
};

export default ZwergenRocker;
