import React from "react";
import Hero from "../../atoms/Hero/Hero";
import Section from "../../atoms/Section/Section";
import SocialMediaBar from "../../molecules/SocialMediaBar/SocialMediaBar";
import Footer from "../../organisms/Footer/Footer";
import { Workshop } from "../../molecules/Workshop/Workshop";
import { useParams } from "react-router-dom";
import { fetchById, structureWorkshopData } from "../../../helper/fetchContent";

const WorkshopSingle = () => {
  let { id } = useParams();
  const [workshopsData, setWorkshops] = React.useState([]);
  React.useEffect(() => {
    const f = async () => {
      const d = await fetchById(id);
      const data = structureWorkshopData({ items: [d] }, true);
      setWorkshops(data);
    };
    f();
  }, []);
  return (
    <div>
      <Hero small>Workshops</Hero>
      <Section>
        {workshopsData.map((ws) => {
          return (
            <Workshop
              img={ws.img}
              title={ws.title}
              subTitle={ws.subTitle}
              date={ws.date}
              day={ws.day}
              description={ws.description}
              dozent={ws.dozent}
              dozentDescription={ws.dozentDesc}
            />
          );
        })}
        {!workshopsData[0] && (
          <div style={{ minHeight: "10rem" }}>
            Zurzeit gibt es keine anstehenden Workshops.
          </div>
        )}
      </Section>
      <SocialMediaBar />
      <Footer />
    </div>
  );
};

export default WorkshopSingle;
