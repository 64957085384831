import React from "react";
import HomeHero from "./../../atoms/HomeHero/HomeHero";
import Section from "../../atoms/Section/Section";

import Footer from "./../../organisms/Footer/Footer";
import CTA from "../../molecules/CTA/CTA";
import { Link } from "react-router-dom";
import News from "../../organisms/News/News";
import SocialMediaBar from "../../molecules/SocialMediaBar/SocialMediaBar";
import { IconNote } from "../../atoms/Icon";
import { variables } from "../../../data/variables";
import TestimonialsSection from "../../molecules/TestimonialsSection/TestimonialsSection";
import HomeImg from "../../../static/img/title.jpg";
import Illu from "../../../static/img/illustration.svg";
import Button from "../../atoms/Button/Button";

const HomePage = () => {
  return (
    <div>
      <HomeHero
        image={HomeImg}
        illustration={
          <div style={{ width: "800px", maxWidth: "100%" }} />
          // <img src={Illu} style={{ width: "800px", maxWidth: "100%" }} />
        }
      >
        <div> Die Musikschule mit Persönlichkeit</div>
        <div
          style={{
            textAlign: "right",
            margin: "3rem 0 2rem 0",
            fontWeight: "normal",
            fontSize: "16px",
            opacity: 0.6,
          }}
        >
          Melde dich jetzt zur Probestunde an und finde dein Instrument!
        </div>
        <Button
          style={{ width: "auto", alignSelf: "flex-end" }}
          href="https://www.axinio.app/booking/forms/trial/53026260-0d3a-490f-a77a-c797b7376889/"
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ margin: "0 .5rem", fontSize: "16px" }}>
              Jetzt anmelden
            </span>
          </div>
        </Button>
      </HomeHero>
      {/* <Section theme="white">
        <p>Liebe SchülerInnen, Eltern und Freunde, </p>
        <p>
          laut Verordnung der hessischen Landesregierung sind nun auch alle
          Musikschulen in Hessen betroffen.
        </p>
        <p>
          <b>
            Ab 02.11. müssen wir wieder auf den Präsenzunterricht verzichten und
            in den Online Modus umschalten. Diese Verordnung ist bis zum
            30.11.2020 gültig.
          </b>{" "}
        </p>
        <p>
          <i>
            Wir haben den Online-Unterricht bereits im Frühjahr größtenteils
            erfolgreich erprobt und individuell durchgeführt. Eure LehrerInnen
            setzen sich mit Euch in Verbindung, um gemeinsam zu besprechen, was
            für Euch aktuell der beste Weg ist, den Unterricht fortzuführen.
          </i>
        </p>
        <p>
          Sollten darüber hinaus Fragen bestehen, sind wir selbstverständlich
          auch persönlich für Euch da. Am besten über{" "}
          <a href="mailto:info@musik-total.net">info@musik-total.net</a> oder{" "}
          <a href="tel:0171/2883434">0171/2883434</a> (Silke Knoll)
        </p>
      <p>
          Eure Gesundheit liegt in unserer Verantwortung, deshalb haben wir uns
          schweren Herzens entschlossen, vor den Sommerferien die Kurse nicht
          mehr stattfinden zu lassen.
        </p>
        <p>
          (Ausführliche Informationen für unsere bestehenden Kurse gab es in
          unserem Schreiben vom 20.05.2020)
        </p>
        <h4 style={{ color: variables.colors.primary }}>
          Sobald es eine verbindliche Zusage seitens der Regierung und des
          zuständigen Gesundheitsamtes gibt, die Kurse wieder in annähernd
          gewohnten Masse neu starten zu lassen, werden wir Euch
          schnellstmöglich informieren und die neuen Kurse hier auf der Homepage
          ausschreiben.
        </h4>
        <p>Bis das dahin fühlt Euch umarmt, Ihr fehlt uns wirklich sehr!</p>
        <p>Wir schicken Euch musikalische Grüße und bleibt alle gesund!</p>
        <p>Eure Silke Knoll mit Team</p> 
    </Section>
      <Section theme="primary">
        <h2>Unser ganz persönliches Anliegen und Bitte an Euch:</h2>
        <p>
          Soweit es Eure wirtschaftliche Lage zulässt, bleibt an unserer Seite
          und steht diese Pandemie mit uns gemeinsam durch. Der
          Online-Unterricht ist für uns Musik Total DozentInnen als
          Kulturschaffende, in dieser so schweren Zeit, die einzig verbliebene
          Einnahmequelle. Und es geht nicht allein um den wirtschaftlichen
          Einbruch, es geht auch darum, weiterhin gebraucht zu werden, Wissen
          und Können vermitteln zu dürfen. Auch für unsere SchülerInnen ist der
          Musikunterricht eine Möglichkeit, eine Auszeit in dieser schwierigen
          Zeit zu nehmen und den „oft verstörenden Gedanken“ eine Pause zu
          gönnen. Das soziale Miteinander hält uns Aufrecht und gibt uns allen
          viel Kraft. Lasst uns gemeinsam hoffen und alles dafür tun, dass die
          Zahlen sinken und wir uns bald gesund und munter vor Ort wieder sehen
          können.
        </p>
        <h2>GEMEINSAM KÖNNEN WIR DAS DURCHSTEHEN!</h2>
      </Section>
      <Section theme="white">
        <p>
          Wir sagen ganz herzlichen Dank für Eure Geduld, Eure Loyalität und
          Euer Durchhaltevermögen!
        </p>
        <p>Liebe Grüße</p>
        <p>Silke und Andi Knoll</p>
        <p>
          mit dem Team von Musik Total: Andre Amberg, Andrei Likhanov, Anna
          Busch, Elina Schmotzer, Felicia Greupner, Klaus Ellenbrand, Felix
          Rogge, Robin Mittenzwei, Julian Knoll, David Neuendorf, Julia Ballin,
          Martin Fink, Holger Böhme, Dennis Korn, Holger Roth, Thomas Bopp und
          Ina Morgan
        </p>
      </Section>
      <div
        style={{
          width: "100%",
          height: "2px",
          background: variables.background.secondaryLight,
        }}
      /> */}
      <Section theme="white">
        <h1>Musik Total: Willkommen in einer großen Familie!</h1>
        <p>
          "Hier fühl ich mich daheim!" - das hören wir immer wieder. Von unseren
          kleinen und großen Schülern, von unseren Dozenten, von Gästen. Und das
          ist uns auch ganz wichtig! Musik Total ist eine Musikschule, aber auch
          eine große Familie. Wir legen viel Wert auf eine persönliche,
          freundschaftliche Atmosphäre. Wer zu uns kommt, soll etwas lernen,
          aber sich auch in seiner Persönlichkeit weiterentwickeln können. Spaß
          haben. An der Musik und mit der Musik wachsen.{" "}
          <Link to="/über-uns" style={{ fontWeight: "bold" }}>
            Mehr Lesen.
          </Link>
        </p>
      </Section>
      <News />
      <CTA
        theme="primary"
        text="Probestunde"
        desc="Melde dich jetzt zur Probestunde an und finde dein Instrument!"
        button={
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconNote theme="primary" size="1.5rem" />
            <span style={{ margin: "0 .5rem" }}>Anmelden</span>
          </div>
        }
      />
      {/* <TestimonialsSection /> */}
      <SocialMediaBar />
      <Footer />
    </div>
  );
};

export default HomePage;
