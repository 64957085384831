import * as React from 'react';
import { Icon } from '.';

export const IconTaperecorder: React.SFC<{}> = props => {
  return (
    <Icon
      d="M37,4c-6.07,0-11,4.93-11,11s4.93,11,11,11s11-4.93,11-11S43.07,4,37,4z M32.51,14.29l-3.46-1.21 c0.181-0.74,0.46-1.48,0.86-2.17c0.399-0.69,0.899-1.311,1.46-1.84l2.77,2.399c-0.42,0.34-0.79,0.761-1.08,1.261 C32.78,13.22,32.6,13.75,32.51,14.29z M37,23.18c-0.8,0-1.58-0.12-2.32-0.33l0.69-3.6c0.51,0.19,1.06,0.29,1.63,0.29 s1.12-0.1,1.63-0.29l0.69,3.6C38.58,23.06,37.8,23.18,37,23.18z M37,18.5c-1.93,0-3.5-1.57-3.5-3.5s1.57-3.5,3.5-3.5 s3.5,1.57,3.5,3.5S38.93,18.5,37,18.5z M41.49,14.29c-0.09-0.54-0.271-1.07-0.55-1.56c-0.29-0.5-0.66-0.921-1.08-1.261l2.77-2.399 c0.561,0.529,1.061,1.149,1.46,1.84c0.4,0.689,0.68,1.43,0.86,2.17L41.49,14.29z M24,15c0,7.17-5.83,13-13,13c-1.77,0-3.46-0.36-5-1 v17h36V27c-1.54,0.64-3.23,1-5,1C29.83,28,24,22.17,24,15z M22,40H10V29h12V40z M31,33h-2v7h-2v-7h-2v-2h2v-2h2v2h2V33z M38,35h-2v5 h-2v-5h-2v-2h2v-4h2v4h2V35z M11,16.5c-0.83,0-1.5-0.67-1.5-1.5s0.67-1.5,1.5-1.5s1.5,0.67,1.5,1.5S11.83,16.5,11,16.5z M14.15,38 H12v-7h8v7h-2.15c-0.13-0.3-0.33-0.56-0.58-0.77L18,33l-2.02,3.76C15.15,36.77,14.45,37.28,14.15,38z M38.5,15 c0,0.83-0.67,1.5-1.5,1.5s-1.5-0.67-1.5-1.5s0.67-1.5,1.5-1.5S38.5,14.17,38.5,15z M22,15c0-6.07-4.93-11-11-11S0,8.93,0,15 s4.93,11,11,11S22,21.07,22,15z M15.49,15.71l3.46,1.21c-0.181,0.74-0.46,1.48-0.87,2.17c-0.39,0.69-0.89,1.311-1.44,1.84 L13.86,18.53c0.42-0.34,0.79-0.761,1.08-1.261C15.22,16.78,15.4,16.25,15.49,15.71z M11,6.82c0.8,0,1.58,0.12,2.32,0.33l-0.69,3.6 c-0.51-0.19-1.06-0.29-1.63-0.29s-1.12,0.1-1.63,0.29l-0.69-3.6C9.42,6.94,10.2,6.82,11,6.82z M5.36,20.93 C4.81,20.4,4.31,19.78,3.92,19.09C3.51,18.4,3.23,17.66,3.05,16.92l3.46-1.21c0.09,0.54,0.271,1.07,0.55,1.56 c0.29,0.5,0.66,0.921,1.08,1.261L5.36,20.93z M7.5,15c0-1.93,1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5s-1.57,3.5-3.5,3.5S7.5,16.93,7.5,15 z" 
      {...props}
    />
  );
};
