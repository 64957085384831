import React from "react";
import { pages } from "../../../data/navigation";
import { pageToUrl } from "../../../helper/pageToUrl";
import { Route, Switch } from "react-router-dom";
import UnderContructionPage from "./../../pages/UnderConstructionPage/UnderContructionPage";
import HomePage from "./../../pages/HomePage/HomePage";
import BandCoaching from "./../../pages/BandCoaching/BandCoaching";
import WindelRocker from "../../pages/WindelRocker/WindelRocker";
import ZwergenRocker from "../../pages/ZwergenRocker/ZwergenRocker";
import Kurse from "./../../pages/Kurse/Kurse";
import Instrumentalunterricht from "./../../pages/Instrumentalunterricht/Instrumentalunterricht";
import RhythmusRocker from "./../../pages/RhythmusRocker/RhythmusRocker";
import SandkastenRocker from "./../../pages/SandkastenRocker/SandkastenRocker";
import Gesangsunterricht from "./../../pages/Gesangsunterricht/Gesangsunterricht";
import Impressum from "./../../pages/ImpressumPage/ImpressumPage";
import UnserTeam from "./../../pages/UnserTeam/UnserTeam";
import MusikTherapie from "./../../pages/MusikTherapie/MusikTherapie";
import UberUnsPage from "./../../pages/UberUnsPage/UberUnsPage";
import Instrumentenkarussell from "./../../pages/Instrumentenkarussell/Instrumentenkarussell";
import MusikschulOrdnung from "./../../pages/MusikschulOrdnung/MusikschulOrdnung";
import Probestunde from "./../../pages/Probestunde/Probestunde";
import Workshops from "./../../pages/Workshops/Workshops";
import WorldRainbowSong from "../../pages/WorldRainbowSong/WorldRainbowSong";
import WorkshopSingle from "../../pages/WorkshopSingle/WorkshopSingle";
import NewsSingle from "../../pages/NewsSingle/NewsSingle";

const Routes = () => {
  return (
    // <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
    //   <div style={{ width: "100%", maxWidth: "1600px" }}>
    <Switch>
      <Route exact path="/" component={HomePage} />
      <Route exact path="/bandcoaching" component={BandCoaching} />
      <Route exact path="/windelrocker" component={WindelRocker} />
      <Route exact path="/zwergenrocker" component={ZwergenRocker} />
      <Route exact path="/sandkastenrocker" component={SandkastenRocker} />
      <Route exact path="/rhythmusrocker" component={RhythmusRocker} />
      <Route
        exact
        path="/instrumentalunterricht"
        component={Instrumentalunterricht}
      />
      <Route exact path="/gesangsunterricht" component={Gesangsunterricht} />
      <Route exact path="/musiktherapie" component={MusikTherapie} />
      <Route exact path="/zwergenrocker" component={ZwergenRocker} />
      <Route exact path="/kurse-für-kinder" component={Kurse} />
      <Route exact path="/impressum" component={Impressum} />
      <Route exact path="/unser-team" component={UnserTeam} />
      <Route exact path="/wer-sind-wir" component={UberUnsPage} />
      <Route exact path="/infos-und-gebühren" component={MusikschulOrdnung} />
      <Route exact path="/probestunde" component={Probestunde} />
      <Route
        exact
        path="/instrumentenkarussell"
        component={Instrumentenkarussell}
      />
      <Route exact path="/workshops-&-specials" component={Workshops} />
      <Route exact path="/über-uns" component={UberUnsPage} />
      <Route exact path="/world-rainbow-song" component={WorldRainbowSong} />
      <Route exact path="/workshop/:id" component={WorkshopSingle} />
      <Route exact path="/news/:id" component={NewsSingle} />
      <Route path="/" component={UnderContructionPage} />
    </Switch>
    //   </div>
    // </div>
  );
};

export default Routes;
