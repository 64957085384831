import React from "react";
import styled from "styled-components";
import Crane from "../../../static/img/crane.svg";
import { variables } from "../../../data/variables";
import Hero from "./../../atoms/Hero/Hero";
import SocialMediaBar from "./../../molecules/SocialMediaBar/SocialMediaBar";
import Footer from "./../../organisms/Footer/Footer";

const Container = styled.div`
  width: 100vw;
  height: calc(100vh - ${variables.sizes.navbar});
  display: flex;
  justify-content: center;
  padding: 1rem;
  align-items: center;
  flex-direction: column;
`;

const UnderContructionPage = () => {
  return (
    <>
      <Container>
        <img src={Crane} style={{ width: "20rem", height: "20rem" }} />
        <h1>Under Construction</h1>
        <p>
          Unsere neue Website befindet sich derzeit noch im Aufbau. Hier
          erscheinen demnächst spannende Neuigkeiten rund um Musik und Dich :)
        </p>
      </Container>
      <SocialMediaBar />
      <Footer />
    </>
  );
};

export default UnderContructionPage;
