import React from "react";
import styled from "styled-components";
import { variables } from "../../../data/variables";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Title = styled.div`
  color: ${props =>
    props.color
      ? variables.background[props.color]
      : variables.background.primary};
  font-weight: bold;
`;

const Table = styled.table`
  width: 100%;
  margin: 1rem 0;
  border-collapse: collapse;
  border-style: hidden;
`;

const TableHead = styled.tr`
  background: ${props =>
    props.color
      ? variables.background[props.color]
      : variables.background.primary};
  color: ${props =>
    props.color ? variables.text[props.color] : variables.text.primary};
  text-align: left;
`;

const Th = styled.th`
  border-right: 2px solid #ccc;
  padding-left: 0.5rem;
  ${props =>
    props.hide &&
    `
    @media (max-width: ${variables.breakpoints.m}px) {
        display:none;
  }`}
`;

const TableRow = styled.tr`
  &:nth-child(odd) {
    background-color: ${variables.background.secondaryLight};
  }
`;

const SmallLabel = styled.td`
  font-size: 0.9rem;
  padding-left: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  color: ${props =>
    props.color
      ? variables.background[props.color]
      : variables.background.primary};
`;

const Td = styled.td`
  font-size: 0.9rem;
  padding-left: 1rem;
  position: relative;
  border-right: 2px solid #ccc;
  ${props =>
    props.hide &&
    `
    @media (max-width: ${variables.breakpoints.m}px) {
        display:none;
  }`}
  ${props =>
    props.label &&
    `
    &::before{
        color:${
          props.color
            ? variables.background[props.color]
            : variables.background.primary
        };
        content:"${props.label}";
        position:absolute;
        transform: rotate(-90deg);
        left:-3rem;
        top:0;
        font-weight:bold;
        font-size:1.5rem;
        text-transform:uppercase;
    }`};
`;

const Spacer = styled.tr`
  height: 1.5rem;
`;

const Tabelle = props => {
  let dropTable = [];
  props.drop && (dropTable = props.drop);

  return (
    <Container>
      {props.title && (
        <Title
          color={props.color}
          contentEditable={props.edit}
          suppressContentEditableWarning={props.edit}
        >
          {props.title}
        </Title>
      )}
      <Table>
        <tbody>
          {props.header && (
            <TableHead color={props.color}>
              {props.header.map((label, i) => (
                <Th
                  contentEditable={props.edit}
                  suppressContentEditableWarning={props.edit}
                  key={label}
                  hide={dropTable.includes(i)}
                >
                  {label}
                </Th>
              ))}
            </TableHead>
          )}
          {props.rows &&
            props.rows.map((row, i) => {
              if (row.content === "spacer") {
                return <Spacer key={i} />;
              } else {
                const mobile = window.innerWidth < variables.breakpoints.m;
                console.log(mobile);
                return (
                  <>
                    {mobile && row.label && (
                      <Spacer>
                        <SmallLabel color={props.color}>{row.label}</SmallLabel>
                      </Spacer>
                    )}
                    <TableRow key={i}>
                      {row.content.map((cell, i) => {
                        if (row.label && i === 0) {
                          return (
                            <Td
                              contentEditable={props.edit}
                              suppressContentEditableWarning={props.edit}
                              key={i}
                              label={!mobile ? row.label : null}
                              color={props.color}
                              hide={dropTable.includes(i)}
                            >
                              {cell}
                            </Td>
                          );
                        } else {
                          return (
                            <Td
                              key={i}
                              contentEditable={props.edit}
                              suppressContentEditableWarning={props.edit}
                              hide={dropTable.includes(i)}
                            >
                              {cell}
                            </Td>
                          );
                        }
                      })}
                    </TableRow>
                  </>
                );
              }
            })}
        </tbody>
      </Table>
    </Container>
  );
};

export default Tabelle;
