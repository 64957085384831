import React from "react";
import WindelRockerImg from "../../../static/img/windelrocker.jpg";
import WindelRockerImg2 from "../../../static/img/windelrocker2.jpg";
import KursTemplate from "./../../templates/KursTemplate/KursTemplate";

const WindelRocker = () => {
  return (
    <div>
      <KursTemplate
        title="Die Windelrocker"
        image={WindelRockerImg}
        polaroid={WindelRockerImg2}
        age="Alter: 1/2 Jahr - 1 1/2 Jahre"
      >
        <p>
          {" "}
          Unsere Windelrocker sind die Kleinsten unserer Musikschule. Wir
          musizieren gemeinsam, mit Eltern oder Großeltern und ihren
          (Enkel-)Babys. Wir hören Musik, wir singen zusammen, rasseln und
          trommeln - oder nehmen gleich die große Pauke. Wir streicheln mit
          bunten Tüchern und schaukeln oder hopsen die Babys im Arm oder auf dem
          Schoß. Wir lachen, tanzen und toben, wir kuscheln und beruhigen.{" "}
        </p>
        <p>
          Wir erobern gemeinsam die musikalische Welt mit Tönen und Geräuschen,
          mit traditionellen und aktuellen Liedern und natürlich mit unseren
          eigenen Songs von „Nieke und den Tonartisten“, die in unserer
          Musikschule entstehen.
        </p>
      </KursTemplate>
    </div>
  );
};

export default WindelRocker;
