import * as React from 'react';
import { Icon } from '.';

export const IconNote: React.SFC<{}> = props => {
  return (
    <Icon
      d="M44,1.86V36c0,1.77-1.12,3.37-3.15,4.51C39.2,41.45,37.03,42,35.05,42h-0.08c-1.79-0.01-3.13-0.47-3.989-1.36 c-0.66-0.689-0.99-1.609-0.961-2.67C30.11,34.4,34.71,32,38.94,32c1.34,0.01,2.319,0.24,3.06,0.61V14.17 c-1.85,0.33-5.91,1.13-11.72,2.79c-5.67,1.62-10.72,4.08-12.3,4.89v17.73C17.99,39.72,18,39.87,18,40c0,1.77-1.11,3.37-3.13,4.51 C13.22,45.45,11.06,46,9.08,46H8.99c-1.77-0.01-3.17-0.49-4.02-1.38c-0.66-0.68-1-1.601-0.97-2.65C4.1,38.4,8.71,36,12.96,36 c1.32,0.01,2.29,0.24,3.02,0.59V10.38l0.56-0.27c0.24-0.13,6.18-3.07,13.19-5.07c8.229-2.35,12.939-3,13.14-3.03L44,1.86z" 
      {...props}
    />
  );
};
