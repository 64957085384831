import React from "react";
import styled from "styled-components";

const Thumb = styled.img`
  cursor: pointer;
  margin:0:
  padding:0;
  transition: filter 500ms;
  :hover {
    filter: brightness(1.5);
  }
`;

export const YTMosaic = () => {
  const [ids, setIds] = React.useState();
  React.useEffect(() => {
    const getData = async () => {
      const data = await fetch(
        "https://www.googleapis.com/youtube/v3/search?part=id&channelId=UCop9EdbgkE8B-FIGrnt75dQ&maxResults=50&order=date&key=AIzaSyByVjcLgY7hXbGsJZvcmuhPut9teemnMVI"
      ).then((d) => d.json());
      const ids = data.items.map((i) => i.id.videoId).filter((id) => !!id);
      setIds(ids);
    };
    getData();
  }, []);

  if (ids) {
    return (
      <div
        style={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(132px,1fr))",
        }}
      >
        {ids.map((id) => {
          return (
            <Thumb
              onClick={() =>
                (window.location = `https://www.youtube.com/watch?v=${id}`)
              }
              width="100%"
              src={`https://img.youtube.com/vi/${id}/default.jpg`}
            />
          );
        })}
      </div>
    );
  } else {
    return null;
  }
};
