import React, { useState } from "react";
import Hero from "./../../atoms/Hero/Hero";
import KursTabelle from "../../organisms/KursTabelle/KursTabelle";
import SocialMediaBar from "../../molecules/SocialMediaBar/SocialMediaBar";
import Footer from "../../organisms/Footer/Footer";
import { Kursinfo } from "./kursinfo";
import KursTabelleEditor from "./../../organisms/KursTabelleEditor/KursTabelleEditor";
import Section from "./../../atoms/Section/Section";
import CTA from "./../../molecules/CTA/CTA";
import { variables } from "../../../data/variables";

const Kurse = () => {
  const [admin, setAdmin] = useState(false);
  const [data, setData] = useState(Kursinfo);
  return (
    <div>
      <Hero>Kurse</Hero>
      <Section style={{ padding: 0, margin: 0 }} />

      {/* {data.map(kurs => (
        <KursTabelle
          edit={admin}
          title={kurs.title}
          data={kurs.data}
          subtitle={kurs.subtitle}
          footer={kurs.footer}
        />
      ))} */}
      <Section>
        <p>
          {/* <b>AKTUELL</b> sind leider keine Kinderkurse im Präsenzunterricht
          möglich. <b>ALTERNATIV</b> bieten wir EUCH die - mittlerweile -
          legendären Wohnzimmer-Zoom-Familienstunden, wöchentlch 30 Minuten, mit
          Silke an. */}
          Aktuell bieten wir euch wieder ausgewählte <b>Präsenzkurse</b> in{" "}
          <span style={{ color: variables.colors.primary, fontWeight: "bold" }}>
            Gelnhausen Höchst
          </span>{" "}
          und{" "}
          <span style={{ color: variables.colors.blue, fontWeight: "bold" }}>
            Bad Soden Salmünster
          </span>{" "}
          an. Bei Interesse ruft uns an oder meldet euch über das{" "}
          <a
            style={{ textDecoration: "underline" }}
            href={
              "https://www.axinio.app/booking/forms/trial/53026260-0d3a-490f-a77a-c797b7376889/"
            }
          >
            Kontaktformular
          </a>
          .
        </p>
        <p>
          Da wir im Innenraum singen, gelten die aktuellen Corona-Regelungen für
          alle Teilnehmer (3G). Wir bitten um Verständnis.
        </p>
      </Section>
      <CTA
        text="Kursanmeldung"
        theme="primary"
        button={
          <div style={{ display: "flex", alignItems: "center" }}>Anmeldung</div>
        }
      />
      <SocialMediaBar />

      <Footer />
    </div>
  );
};

export default Kurse;
