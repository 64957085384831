import React from "react";
import styled from "styled-components";
import { pages } from "../../../data/navigation";
import { variables } from "../../../data/variables";
import { Link } from "react-router-dom";
import { pageToUrl } from "../../../helper/pageToUrl";
import { useState } from "react";
import LogoImage from "../../../static/mtlogo grad@2x.png";
import RainbowImage from "../../../static/img/rainbow/menu_header.png";

const Wrapper = styled.div`
  height: ${variables.sizes.navbar};
`;

const Spacer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 12rem;
  z-index: 49;
`;

const Bar = styled.div`
  height: ${variables.sizes.navbar};
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
  padding: 0 1.5%;
  background: ${variables.background.white};
  color: ${variables.text.white};
  z-index: 500;
  border-bottom: 1px solid ${variables.background.secondaryLight};
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
`;

const SubBar = styled(Bar)`
  background: ${variables.background.secondaryLight};
  color: black;
  position: absolute;
  max-width: 100%;
  top: ${(props) => (props.open ? variables.sizes.navbar : 0)};
  left: 0;
  right: 0;
  z-index: 50;
  transition: all 120ms ease-in;
  display: flex;
  justify-content: center;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);

  a {
    margin-right: 1rem;
    font-size: 0.9rem;
    white-space: nowrap;

    :hover {
      color: ${variables.text.secondary};
    }
  }
`;

const PrimaryItemWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex: 3;
  align-items: center;
  justify-content: flex-start;
`;

const PrimaryItem = styled.div`
  white-space: nowrap;
  font-size: 0.9rem;
  font-weight: bold;
  margin: 0 1rem;
  height: 3rem;
  flex: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  transition: all 100ms ease-in;
  :hover {
    border-bottom: 2px solid ${variables.text.primary};
  }
`;

const SecondaryItemWrapper = styled.div`
  display: flex;
  height: 100%;
  flex: 1;
  justify-content: flex-end;
`;

const SecondaryItem = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  font-size: 0.8rem;
  margin: 0 0.5rem;
`;

const Logo = styled.img`
  height: 2.2rem;
`;
const RainbowHeader = styled.img`
  height: 3.5rem;
  margin-left: 1rem;
`;

const DesktopNavbar = (props) => {
  const [subBarOpen, setSubBar] = useState(false);
  const [subBarItems, setSubBarItems] = useState([]);

  const onEnter = (pages) => {
    setSubBarItems(pages);
    setSubBar(true);
  };

  const onLeave = () => {
    setSubBar(false);
  };

  return (
    <Wrapper onMouseLeave={onLeave}>
      <Spacer />
      <Bar>
        <Link to="/" style={{ paddingTop: 6 }}>
          <Logo src={LogoImage} />
        </Link>
        <Link to="/world-rainbow-song" style={{ paddingTop: 6 }}>
          <RainbowHeader src={RainbowImage} />
        </Link>
        <PrimaryItemWrapper>
          {pages.map((page) => {
            if (page.primary) {
              return (
                <Link
                  key={page.name}
                  to={page.home ? pageToUrl(page.name) : "/"}
                  style={{
                    height: "100%",
                  }}
                >
                  <PrimaryItem
                    onMouseEnter={page.pages && (() => onEnter(page.pages))}
                  >
                    {page.name}
                  </PrimaryItem>
                </Link>
              );
            }
            return null;
          })}
        </PrimaryItemWrapper>
        <SecondaryItemWrapper>
          {pages.map((page) => {
            if (!page.primary) {
              if (page.ext) {
                return (
                  <a href={page.ext}>
                    <SecondaryItem
                      onMouseEnter={page.pages && (() => onEnter(page.pages))}
                    >
                      {page.name}
                    </SecondaryItem>
                  </a>
                );
              }
              return (
                <Link
                  key={page.name}
                  to={page.home ? pageToUrl(page.name) : "/"}
                  style={{
                    textDecoration: "none",
                    color: "unset",
                    height: "100%",
                  }}
                >
                  <SecondaryItem
                    onMouseEnter={page.pages && (() => onEnter(page.pages))}
                  >
                    {page.name}
                  </SecondaryItem>
                </Link>
              );
            }
            return null;
          })}
        </SecondaryItemWrapper>
      </Bar>
      <SubBar open={subBarOpen}>
        {subBarItems.map((item) => {
          return (
            <Link key={item[0]} to={pageToUrl(item[0])}>
              {item[0]}
            </Link>
          );
        })}
      </SubBar>
    </Wrapper>
  );
};

export default DesktopNavbar;
