import React from "react";
import MediaQuery from "react-responsive";
import DesktopNavbar from "./DesktopNavbar";
import MobileNavbar from "./MobileNavbar";
import { variables } from "../../../data/variables";
const Navbar = () => {
  return (
    <MediaQuery minDeviceWidth={variables.breakpoints.m}>
      {matches => {
        if (matches) {
          return <DesktopNavbar />;
        } else {
          return <MobileNavbar />;
        }
      }}
    </MediaQuery>
  );
};

export default Navbar;
