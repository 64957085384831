const mockData = [
  {
    title: "Kurse in Gelnhausen-Höchst",
    header: ["Kurs", "Altersgruppe", "Ort", "Uhrzeit"],
    drop: [3],
    columnCount: 4,
    rows: [
      {
        content: ["Sandkastenrocker", "ab 3 Jahre", "GN-Höchst", "15:40-16:25"],
        label: "MO"
      },
      {
        content: [
          "Musik & Phantasie",
          "ab 4,5 Jahre",
          "GN-Höchst",
          "16:30-17:15"
        ]
      },
      {
        content: ["Windelrocker", "ab 18 Monate", "GN-Höchst", "17:15-18:00"]
      },
      { content: "spacer" },
      {
        content: [
          "Zwergenrocker",
          "ab 18 Monate (Eltern-Kind)",
          "GN-Höchst",
          "10:15-10:55"
        ],
        label: "Mi"
      },
      {
        content: [
          "Windelrocker",
          "ab 8 Monate (Eltern-Kind)",
          "GN-Höchst",
          "11:00-11:40"
        ]
      },
      {
        content: [
          "Zwergenrocker",
          "ab 12 Monate (Eltern-Kind)",
          "GN-Höchst",
          "15:30-16:10"
        ]
      },
      {
        content: [
          "Sandkastenrocker",
          "ab 18 Monate (Eltern-Kind)",
          "GN-Höchst",
          "16:20-17:00"
        ]
      },
      {
        content: [
          "Zwergenrocker",
          "ab 2 Jahre (Eltern-Kind)",
          "GN-Höchst",
          "16:50-17:30"
        ]
      },
      {
        content: ["Sandkastenrocker", "ab 3 Jahre", "GN-Höchst", "15:30-16:10"]
      },
      {
        content: [
          "Musik & Phantasie",
          "ab 4,5 Jahre",
          "GN-Höchst",
          "16:00-16:20"
        ]
      },
      {
        content: [
          "Musik & Phantasie",
          "ab 4,5 Jahre",
          "GN-Höchst",
          "17:00-17:40"
        ]
      },
      { content: "spacer" },
      {
        content: [
          "Zwergenrocker",
          "ab 18 Monate (Eltern-Kind)",
          "GN-Höchst",
          "15:30-16:10"
        ],
        label: "Do"
      },
      {
        content: [
          "Zwergenrocker",
          "ab 18 Monate (Eltern-Kind)",
          "GN-Höchst",
          "16:15-16:55"
        ]
      },
      {
        content: [
          "Zwergenrocker",
          "ab 2 Jahre (Eltern-Kind)",
          "GN-Höchst",
          "17:00-17:40"
        ]
      }
    ]
  },
  {
    title: "Musik am Wochenende",
    drop: [3],
    columnCount: 4,
    rows: [
      {
        content: ["Zwergenrocker", "ab 18 Monate", "GN-Höchst", "10:00-10:40"],
        label: "Sa"
      },
      {
        content: ["Windelrocker", "ab 12 Monate", "GN-Höchst", "11:00-11:40"]
      }
    ]
  },
  {
    title: "Kurse in Bad Soden-Salmünster",
    color: "blue",
    drop: [3],
    header: ["Kurs", "Altersgruppe", "Ort", "Uhrzeit"],
    columnCount: 4,
    rows: [
      {
        content: [
          "Zwergenrocker",
          "ab 2,5 Jahre (Eltern-Kind)",
          "BSS-Hausen",
          "15:15-15:55"
        ],
        label: "Fr"
      },
      {
        content: [
          "Musik & Phantasie",
          "ab 4,5 Jahre",
          "BSS-Hausen",
          "16:00-16:45"
        ]
      },
      {
        content: [
          "Zwergenrocker",
          "ab 18 Monate (Eltern-Kind)",
          "BSS-Hausen",
          "16:45-17:25"
        ]
      },
      {
        content: [
          "Musik & Phantasie",
          "ab 4,5 Jahre",
          "GN-Höchst",
          "15:40-16:25"
        ]
      },
      {
        content: [
          "Musik & Phantasie",
          "ab 4,5 Jahre",
          "GN-Höchst",
          "15:40-16:25"
        ]
      },
      {
        content: [
          "Musik & Phantasie",
          "ab 4,5 Jahre",
          "GN-Höchst",
          "15:40-16:25"
        ]
      },
      {
        content: [
          "Musik & Phantasie",
          "ab 4,5 Jahre",
          "GN-Höchst",
          "15:40-16:25"
        ]
      },
      {
        content: [
          "Musik & Phantasie",
          "ab 4,5 Jahre",
          "GN-Höchst",
          "15:40-16:25"
        ]
      },
      { content: "spacer" },
      {
        content: [
          "Musik & Phantasie",
          "ab 4,5 Jahre",
          "GN-Höchst",
          "15:40-16:25"
        ],
        label: "Di"
      },
      {
        content: [
          "Musik & Phantasie",
          "ab 4,5 Jahre",
          "GN-Höchst",
          "15:40-16:25"
        ]
      },
      {
        content: [
          "Musik & Phantasie",
          "ab 4,5 Jahre",
          "GN-Höchst",
          "15:40-16:25"
        ]
      },
      {
        content: [
          "Musik & Phantasie",
          "ab 4,5 Jahre",
          "GN-Höchst",
          "15:40-16:25"
        ]
      },
      {
        content: [
          "Musik & Phantasie",
          "ab 4,5 Jahre",
          "GN-Höchst",
          "15:40-16:25"
        ]
      },
      {
        content: [
          "Musik & Phantasie",
          "ab 4,5 Jahre",
          "GN-Höchst",
          "15:40-16:25"
        ]
      },
      {
        content: [
          "Musik & Phantasie",
          "ab 4,5 Jahre",
          "GN-Höchst",
          "15:40-16:25"
        ]
      }
    ]
  }
];

export const Kursinfo = [
  {
    title: "Unsere Kids-Kurse!",
    subtitle: "Jetzt geht es wieder los:",
    footer:
      "Das sind einige unserer aktuellen Kurse. Für weitere Kurse und Unterrichtsmöglichkeiten ruft uns einfach an oder schreibt und eine E-Mail. Bei interesse schauen wir gern nach freiem Platz! :)",
    data: mockData
  }
];
