import React from "react";
import Section from "./../../atoms/Section/Section";
import styled from "styled-components";
import Avatar from "./../../atoms/Avatar/Avatar";
import Andi from "../../../static/img/lehrer/andi_knoll.jpg";
import Dennis from "../../../static/img/lehrer/dennis_korn.jpg";
import Felix from "../../../static/img/lehrer/felix_rogge.jpg";
import Sebastian from "../../../static/img/lehrer/sebastian_eichenauer.jpg";
import { variables } from "./../../../data/variables";

const InfoBox = styled.div`
  display: flex;
  overflow: hidden;
  @media (max-width: ${variables.breakpoints.s}px) {
    flex-direction: column;
    align-items: center;
  }
  grid-column: 1/-1;
  grid-row: 2;
  height: ${props => (props.open ? "200px" : "0")};
  transition: height 200ms ease-out;
  background-color: ${variables.background.secondaryLight};
`;

const Content = styled.div`
  padding: 0 1rem;
  flex: 3;
`;

const AvatarBox = styled.div`
  display: grid;
  flex: 1;
  @media (max-width: ${variables.breakpoints.s}px) {
    display: flex;
    padding-bottom: 2rem;
    div {
      margin: 0 1rem;
    }
  }
  grid-template-columns: repeat(auto-fit, 3rem);
  grid-template-rows: repeat(auto-fit, 3rem);
  grid-gap: 1rem;
`;

const InstrumentInfoBlock = props => {
  const { open } = props;
  return (
    <InfoBox open={open}>
      <AvatarBox>
        <Avatar size={3} image={Andi} />
        <Avatar size={3} image={Sebastian} />
        <Avatar size={3} image={Felix} />
        <Avatar size={3} image={Dennis} />
      </AvatarBox>
      <Content>{props.children}</Content>
    </InfoBox>
  );
};

export default InstrumentInfoBlock;
