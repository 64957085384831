import React from "react";
import styled from "styled-components";
import { variables } from "../../../data/variables";
import { isMobile } from "../../../helper/isMobile";
import Section from "./../Section/Section";

const HeroWrapper = styled.div`
  width: 100%;
  ${(props) =>
    !props.small && `height: calc(100vh - ${variables.sizes.navbar});`}
  overflow: hidden;
  display: flex;
  align-items: center;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center center;
  background-repeat: none none;
  position: relative;
`;

const Content = styled.div`
  display: flex;
  padding: 3rem;
  margin: auto;
  max-width: 1400px;
  align-items: center;
  justify-content: center;
  text-align: right;
  font-size: 1.5rem;
  flex-wrap: wrap;

  word-break: break-word;
  font-weight: bold;

  @media (min-width: ${variables.breakpoints.s}px) {
    font-size: 2rem;
  }
  @media (min-width: ${variables.breakpoints.m}px) {
    font-size: 3rem;
  }
`;

export const Heading = styled.h1`
  font-size: 1.5rem;
  text-align: center;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  &::after {
    content: "";
    margin-top: 1rem;
    width: 15rem;
    max-width: 50vw;
    border-bottom: 2px solid ${variables.background.secondaryLight};
  }
`;

const HomeHero = (props) => {
  return (
    <HeroWrapper image={props.image} small={props.small} style={props.style}>
      <Content style={props.style} small={props.small}>
        <div
          style={{
            flex: "1 1 500px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          {!isMobile() && props.illustration}
        </div>
        <div
          style={{
            marginTop: "3rem",
            display: "flex",
            flex: "1 1 500px",
            color: "white",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            flexDirection: "column",
          }}
        >
          {props.children}
        </div>
      </Content>
    </HeroWrapper>
  );
};

export default HomeHero;
