import React from "react";
import styled from "styled-components";
import Header from "../../../static/img/rainbow/header.jpg";
import Kinder from "../../../static/img/rainbow/kinder.jpg";
import { variables } from "../../../data/variables";
import Section from "../../atoms/Section/Section";
import Hero from "./../../atoms/Hero/Hero";
import SocialMediaBar from "./../../molecules/SocialMediaBar/SocialMediaBar";
import Footer from "./../../organisms/Footer/Footer";
import { isMobile } from "../../../helper/isMobile";
import CTA from "../../molecules/CTA/CTA";
import { Sheets } from "./Sheets";
import { Presse } from "./Presse";
import { YTMosaic } from "./YTMosaic";

const KinderImg = styled.img`
  object-fit: contain;
  flex: 1;
  max-width: 100%;
  margin-bottom: -2rem;
  @media (min-width: ${variables.breakpoints.m}px) {
    margin-bottom: -5rem;
  }
`;

const VideoWrapper = styled.div`
  padding-bottom: 56.25%;
  padding-top: 0;
  height: 0;
  width: 100%;
  position: relative;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const WorldRainbowSong = () => {
  return (
    <div>
      <Hero
        image={Header}
        style={{
          backgroundPosition: "center center",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          height: isMobile() ? "40vh" : undefined,
        }}
      />
      <Section>
        <VideoWrapper>
          <iframe
            // width="100%"
            // height="315"
            src="https://www.youtube.com/embed/odIn0XEkUfg"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </VideoWrapper>
      </Section>
      <Section>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexirection: "column-reverse",
            alignItems: "flex-end",
            maxWidth: "100%",
          }}
        >
          <div style={{ flex: 1 }}>
            <h1 style={{ margin: 0, fontStyle: "Italic" }}>
              Hallo, ich bin Silke,
            </h1>
            <p>
              Sängerin, Musikpädagogin, Kinderliedermacherin und
              Musiktherapeutin.
            </p>
            <p>
              Inspiriert wurde ich von Regenbogenbildern, die Anfang der Corona
              Krise in den Fenstern hingen. Die Idee kam aus Italien, dort haben
              sich Kinder mit ihren gemalten Bildern mit der Message{" "}
              <b>„Wir bleiben zu Hause“</b> sichtbar gemacht. Da ich seit 23
              Jahren als Pädagogin im Musikbereich tätig bin, dachte ich alles
              was bunt ist braucht eine Melodie. Ein Mutmachlied, was Kinder auf
              der ganzen Welt, während der Pandemie, verbindet. So entstand der
              World Rainbow Song.
            </p>
            <p>
              Mittlerweile wurde mein Lied in <b>30 Sprachen</b> übersetzt und
              weitere stehen aus. Außerdem freuen wir uns auf über <b>33.000</b>{" "}
              YouTube-Klicks und die World-Rainbow-Community wächst täglich mit
              Videos aus aller Welt. Mitarbeiter/innen verschiedener
              Kindergärten nehmen für Kinder, die die Einrichtung aktuell nicht
              besuchen dürfen, meinen Song auf und schicken ihn als Videogruß in
              die Familien. Kinderchöre üben den Song via Internet und aus der
              Türkei erreichte uns ein Unterrichtsvideo. Außerdem beginnt er in
              Grundschulen die Runde zu machen.{" "}
            </p>
            <h3 style={{ color: variables.colors.primary }}>
              Ich möchte mit meinem Lied die Menschen glücklicher und die Welt
              bunter machen!
            </h3>
          </div>

          <KinderImg src={Kinder} />
        </div>
      </Section>
      <CTA
        theme="primary"
        text="Regenbogen für alle"
        button="Zum Song"
        desc="Alle Versionen des Songs gibt es auf unserem Youtube-Channel!"
        to="https://www.youtube.com/channel/UCop9EdbgkE8B-FIGrnt75dQ/"
      />
      <Sheets />
      <YTMosaic />
      <Presse />
      <SocialMediaBar />
      <Footer />
    </div>
  );
};

export default WorldRainbowSong;
