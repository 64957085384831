import React from "react";
import Hero from "./../../atoms/Hero/Hero";
import Section from "../../atoms/Section/Section";
import SocialMediaBar from "../../molecules/SocialMediaBar/SocialMediaBar";
import Footer from "../../organisms/Footer/Footer";
import CTA from "./../../molecules/CTA/CTA";
import TextBg from "../../../static/img/instrumentalBg.jpg";
import InstrumentalBg from "../../../static/img/instrumental.jpg";
import {
  Icon,
  IconSynth,
  IconPiano,
  IconSnare,
  IconAccordion,
  IconFlute,
  IconSax,
  IconBass,
} from "../../atoms/Icon";
import styled from "styled-components";
import { IconGuitar } from "./../../atoms/Icon/IconGuitar";
import { IconAcoustic } from "./../../atoms/Icon/IconAcoustic";
import { variables } from "../../../data/variables";
import InstrumentInfoBlock from "../../molecules/InstrumentInfoBlock/InstrumentInfoBlock";
import { IconSpeaker } from "./../../atoms/Icon/IconSpeaker";

const Instrument = styled.li`
  list-style: none;
  display: flex;
  margin: 2rem 0;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
  svg {
    margin-bottom: 1rem;
  }
  :hover {
    filter: brightness(120%);
  }
`;

const List = styled.ul`
  display: grid;
  padding: 0;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
`;

class Instrumentalunterricht extends React.Component {
  state = { open: undefined };

  setOpen = (type) => {
    // this.state.open === type
    //   ? this.setState({ open: undefined })
    //   : this.setState({ open: type });
  };

  render() {
    return (
      <div>
        <Hero
          image={InstrumentalBg}
          style={{ objectFit: "cover", backgroundPosition: "center bottom" }}
        >
          Instrumentalunterricht
        </Hero>
        <Section theme="primary">
          <b>
            Rockstar an der Gitarre, kräftig in die Drums langen, gefühlvoller
            Gesang oder fette Sounds an den Keys: Bei uns findet jeder sein
            Lieblingsinstrument!
          </b>
        </Section>
        <Section
          style={{
            backgroundImage: `url('${TextBg}')`,
            backgroundPosition: "right bottom",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        >
          <h3>Unser Unterrichtskonzept</h3>
          <p>
            Nicht stur nach Buch, sondern nach Lust und Laune! Musik soll Spaß
            machen. Und jeder hat seinen eigenen Zugang dazu.{" "}
          </p>
          <p>
            Unsere Dozenten sind nicht nur hervorragende Musiker, sondern ebenso
            gute Pädagogen. Nach Schema F geht hier nichts – wir richten uns
            nach den Bedürfnissen unserer Schülerinnen und Schüler. Wir schauen
            uns genau an, wie wir jeden Einzelnen am besten fördern können. Und
            wie jeder auf seine Weise mit seinem Instrument glücklich wird. Im
            Einzelunterricht oder kleinen Gruppen mit 2-3 Schülern. Und
            natürlich angepasst an das, was jeder vorhat.{" "}
          </p>
          <p>
            Ein Instrument ist toll, um sich damit einen kreativen Ausgleich zum
            Alltag zu schaffen. Das schätzen vor allem unsere Großen. Wer will
            und besondere Ambitionen hat, kann sich aber auch ganz gezielt von
            uns auf ein Musikstudium vorbereiten lassen. <b>Alles geht!</b>
          </p>
        </Section>
        <Section theme="secondaryLight">
          <h2>Unser Unterricht - einzeln und in Gruppen</h2>
          <List>
            <InstrumentInfoBlock open={this.state.open === "acoustic"}>
              "Für alle, die die Stromrechnung nicht Zahlen können und trotzdem
              Gitarre lernen wollen"
            </InstrumentInfoBlock>
            <InstrumentInfoBlock open={this.state.open === "electric"}>
              "Dein Papa höhrt gerne Metallica aber du magst lieber Hip Hop?
              Pech gehabt!!"
            </InstrumentInfoBlock>
            <InstrumentInfoBlock open={this.state.open === "keys"}>
              "Glückwunsch! Sie haben das beste Instrument gefunden!"
            </InstrumentInfoBlock>
            <InstrumentInfoBlock open={this.state.open === "piano"}>
              "Für Elise, für jeden, das ganze Jahr, 24/7"
            </InstrumentInfoBlock>
            <InstrumentInfoBlock open={this.state.open === "drums"}>
              "Der passende Unterricht für jeden fiesen Nachbarn. Hier lernen
              sie, wie sie das Meiste aus ihrem Crash rausholen können"
            </InstrumentInfoBlock>
            <InstrumentInfoBlock open={this.state.open === "accordion"}>
              "Will das wirklich noch jemand lernen?"
            </InstrumentInfoBlock>
            <InstrumentInfoBlock open={this.state.open === "clarinett"}>
              "Unterricht für schüterne. Ganz vorne im Orchester, trotzdem höhrt
              sie niemand raus."
            </InstrumentInfoBlock>
            <InstrumentInfoBlock open={this.state.open === "sax"}>
              "Ist das Jazz oder kann das weg. Jede Woche eine Tonleiter!"
            </InstrumentInfoBlock>
            <InstrumentInfoBlock open={this.state.open === "bass"}>
              "Wenn die vorrigen Instrumente Ihnen nicht zusagen oder zu schwer
              sind haben wir immernoch Bass im Angebot. Der Zonk unter den
              Instrumenten."
            </InstrumentInfoBlock>
            <Instrument onClick={() => this.setOpen("acoustic")}>
              <IconAcoustic /> Akustische Gitarre
            </Instrument>
            <Instrument onClick={() => this.setOpen("electric")}>
              <IconGuitar />
              E-Gitarre
            </Instrument>
            <Instrument onClick={() => this.setOpen("keys")}>
              <IconSynth />
              Keyboard
            </Instrument>
            <Instrument onClick={() => this.setOpen("piano")}>
              <IconPiano />
              Klavier
            </Instrument>
            <Instrument onClick={() => this.setOpen("drums")}>
              <IconSnare />
              Schlagzeug
            </Instrument>
            <Instrument onClick={() => this.setOpen("accordion")}>
              <IconAccordion />
              Akkordeon
            </Instrument>
            <Instrument onClick={() => this.setOpen("clarinett")}>
              <IconFlute />
              Klarinette
            </Instrument>
            <Instrument onClick={() => this.setOpen("sax")}>
              <IconSax />
              Saxofon
            </Instrument>
            <Instrument onClick={() => this.setOpen("bass")}>
              <IconBass />
              E-Bass
            </Instrument>
          </List>
        </Section>
        <CTA
          text="JETZT Probestunde ausmachen!"
          theme="primary"
          button={
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconSpeaker theme="primary" size="1.5rem" />
              <span style={{ margin: "0 .5rem" }}>
                Ich möchte Musik machen!
              </span>
              <IconSpeaker theme="primary" size="1.5rem" />
            </div>
          }
        />
        <SocialMediaBar />
        <Footer />
      </div>
    );
  }
}

export default Instrumentalunterricht;
