import React from "react";
import Section from "../../atoms/Section/Section";
import styled from "styled-components";
import { variables } from "../../../data/variables";
import Avatar from "../../atoms/Avatar/Avatar";
import { Link } from "react-router-dom";
import { history } from "../../../App";

const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 3rem;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  :hover {
    background: ${variables.button.background.white};
    color: ${variables.button.text.white};
  }
`;

const Name = styled.div`
  margin: 1rem 0 2.5rem 0;
  font-weight: bold;
`;

const Quote = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
`;

const TestimonialBlock = props => {
  const { text, name = "Schüler", image } = props;
  return (
    <Block onClick={() => history.push("dein-weg-mit-uns")}>
      <Avatar size={7} image={image} />
      <Name>{name}</Name>
      <Quote>{text}</Quote>
    </Block>
  );
};

export default TestimonialBlock;
