import axios from "axios";
import moment from "moment";
moment.locale("de");

const API_BASE_URL = "https://cdn.contentful.com";
const API_SPACE_ID = "0i86pa3sk2uo";
const API_TOKEN = "ZVjZX0dq3-gp8vtUyiFUpiLEASemyajiLIaTHfWEF7Q";

export const fetchContent = async (type) => {
  const request = await axios.get(
    `${API_BASE_URL}/spaces/${API_SPACE_ID}/entries?access_token=${API_TOKEN}&content_type=${type}`
  );
  return request.data;
};

export const fetchById = async (id) => {
  const request = await axios.get(
    `${API_BASE_URL}/spaces/${API_SPACE_ID}/entries/${id}?include=2&access_token=${API_TOKEN}`
  );
  return request.data;
};

export const fetchAsset = async (id) => {
  const request = await axios.get(
    `${API_BASE_URL}/spaces/${API_SPACE_ID}/environments/master/assets/${id}?access_token=${API_TOKEN}`
  );
  return request.data;
};

export const fetchWorkshops = async () => {
  const d = await fetchContent("workshop").then((res) => {
    return structureWorkshopData(res);
  });
  return d;
};

export const fetchNews = async () => {
  const d = await fetchContent("news").then((res) => {
    debugger;
    return structureNewsData(res, true);
  });
  return d;
};

export const fetchNewsSingle = async (id) => {
  const item = await fetchById(id);
  if (!item) return;
  const asset = await fetchAsset(item.fields.bild.sys.id);
  debugger;
  return structureNewsData(
    { items: [item], includes: { Asset: [asset] } },
    true
  );
};

export const structureWorkshopData = (res, noFilter) => {
  let pics = {};

  res.includes &&
    res.includes.Asset.forEach((e) => {
      pics[e.sys.id] = e.fields.file.url;
    });

  const data = res.items
    .filter((ws) => noFilter || new Date(ws.fields.datum) > new Date())
    .map((ws) => {
      return {
        title: ws.fields.titel,
        subTitle: ws.fields.untertitel,
        dozent: ws.fields.dozent,
        dozentDesc: ws.fields.dozentBeschreibung,
        description: ws.fields.beschreibung,
        date: moment(ws.fields.datum).format("DD.MM.YYYY"),
        img: pics[ws.fields.bild.sys.id],
        day: `${moment(ws.fields.datum)
          .format("dddd")
          .toUpperCase()} - ${moment(ws.fields.datum).format("HH:MM")} UHR`,
      };
    });
  return data;
};

export const structureNewsData = (res, noFilter) => {
  let pics = {};

  res.includes &&
    res.includes.Asset.forEach((e) => {
      pics[e.sys.id] = e.fields.file.url;
    });

  const data = res.items
    .filter((ws) => noFilter || new Date(ws.sys.updatedAt) > new Date())
    .map((ws) => {
      return {
        title: ws.fields.headline,
        inhalt: ws.fields.inhalt,
        id: ws.sys.id,
        date: moment(ws.sys.createdAt).format("DD.MM.YYYY"),
        img: pics[ws.fields.bild.sys.id],
      };
    });
  return data;
};
