import React from "react";
import RhythmusRockerImg from "../../../static/img/rhythmusrocker.jpg";
import KursTemplate from "./../../templates/KursTemplate/KursTemplate";

const RhythmusRocker = () => {
  return (
    <KursTemplate
      title="Die Rhythmusrocker"
      image={RhythmusRockerImg}
      polaroid={RhythmusRockerImg}
      age="Alter: 4 Jahre - 6 Jahre"
    >
      <p>
        Unsere Rhythmusrocker, sind kurz davor, ein Instrument zu erlernen. Sie
        haben bereits spielerisch die Notenwelt erkundet. Wir singen und tanzen
        nicht nur zu unseren eigenen Songs von „Nieke und den Tonartisten“, die
        in unserer Musikschule entstehen und anderen fetzigen, aber auch
        traditionellen Liedern.
      </p>
      <p>
        Wir befassen uns mit der Enstehung der Note, wir basteln und Kneten
        diese, um die Formen zu verinnerlichern. Wir spielen leichte Rhythmen
        auf den Trommeln. Außerdem lernen wir verschiedene Instrumentengruppen
        kennen und hören.
      </p>
      <p>
        Im Anschluss an diesen Kurs sind wir bereit für das
        Instrumentenkarussell, den Keyboardgruppenunterricht mit Spiel und Spaß
        für Einsteiger, oder für die Melodika.
      </p>
    </KursTemplate>
  );
};

export default RhythmusRocker;
