import React, { Component } from "react";
import logo from "./logo.svg";
import "./App.css";
import Navbar from "./components/molecules/NavBar/Navbar";
import { Router, Route, Link, Switch } from "react-router-dom";
import Routes from "./components/molecules/Routes/Routes";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();
library.add(fab);
class App extends Component {
  render() {
    return (
      <Router history={history}>
        <>
          <Navbar />
          <Routes />
        </>
      </Router>
    );
  }
}

export default App;
