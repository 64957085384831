import React from "react";
import styled from "styled-components";
import { variables } from "../../../data/variables";

const Wrapper = styled.div`
  transform: rotate(-3deg);

  @media (max-width: ${variables.breakpoints.m}px) {
  }
  margin: 0 1rem;
  /* max-width: 60%; */

  max-height: 50%;
  overflow: hidden;
  flex: 1;
  background-color: white;
  padding: 1rem;
  padding-bottom: 3rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;
const PolaroidWrapper = props => {
  return <Wrapper>{props.children}</Wrapper>;
};

export default PolaroidWrapper;
