import * as React from 'react';
import { Icon } from '.';

export const IconSax: React.SFC<{}> = props => {
  return (
    <Icon
      d="M44.921,30.38c-0.15,0.38-0.52,0.62-0.92,0.62c-2.42,0-4.82,0.99-6.01,8.16c-0.71,4.279-3.71,8.84-9.99,8.84 c-3.19,0-5.97-1.09-7.81-3.061c-1.56-1.66-2.34-3.799-2.19-6.01c0.77-11.52,1.69-19.1,2.3-24.109c1.01-8.33,0.95-9.08-0.75-9.93 c-0.03-0.011-0.07-0.031-0.15-0.031c-0.33,0-0.9,0.261-1.5,0.531c-0.98,0.43-2.27,1.01-3.9,1.149V2.23 c0.93-0.311,1.56-0.69,2.13-1.03c1.02-0.62,1.99-1.2,3.87-1.2c2.41,0,6,1.6,6,5.99c0,0.01,0,0.01,0,0.01h1V5h2v4h-2V8h-1v3h1v-1h2v4 h-2v-1h-1v3h1v-1h2v4h-2v-1h-1v3h1v-1h2v4h-2v-1h-1v3h1v-1h2v4h-2v-1h-1v3h1v-1h2v4h-2v-1h-1v4.75c0,1.21,1.35,1.25,1.5,1.25 s1.5-0.04,1.5-1.25c0-6.16,1.05-9.08,3.07-14.12c0.63-1.569,0.64-2.97,0.04-4.18c-0.221-0.44-0.091-0.97,0.31-1.261 c0.39-0.289,0.94-0.239,1.29,0.101l11,11C44.991,29.58,45.081,30.01,44.921,30.38z M1.88,5.707l10.121,1.445V1.819l-7.48,1.247 L1.88,5.707z" 
      {...props}
    />
  );
};
