import React from "react";
import Hero from "../../atoms/Hero/Hero";
import Section from "../../atoms/Section/Section";
import LehrerBlock from "./LehrerBlock";
import Andi from "../../../static/img/lehrer/andi_knoll.jpg";
import Nadiia from "../../../static/img/lehrer/nadiia.jpg";
import Thomas from "../../../static/img/lehrer/thomas_bopp.jpg";
import Robin from "../../../static/img/lehrer/robin_mittenzwei.jpg";
import Klaus from "../../../static/img/lehrer/klaus_ellenbrand.jpg";
import Holgi from "../../../static/img/lehrer/holger_roth.jpg";
import Silke from "../../../static/img/lehrer/silke_knoll.jpg";
import Holger from "../../../static/img/lehrer/holger_böhme.jpg";
import Anna from "../../../static/img/lehrer/anna_busch.jpg";
import David from "../../../static/img/lehrer/david_neuendorf.jpg";
import Martin from "../../../static/img/lehrer/martin_fink.jpg";
import Janina from "../../../static/img/lehrer/janina.jpg";
import Jessica from "../../../static/img/lehrer/jessica.webp";

import styled from "styled-components";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  grid-gap: 1rem 1rem;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`;

const UnserTeam = () => {
  return (
    <div>
      <Hero>Unser Team</Hero>
      <Section>
        <Grid>
          <LehrerBlock
            image={Andi}
            name="Andi Knoll"
            instruments="Gitarre, Keyboard, Akkordeon, Musik Total Inhaber"
            vita="Abschluss der Fachhochschule Fulda als Staatl. anerk. Dipl.-Sozialpädagoge. Schwerpunkt Integration und Musik im Bereich des Kinder- und Jugendalters. Musiker seit 1992. Seit 1996 Inhaber und Dozent der Musikschule Musik Total."
          />
          <LehrerBlock
            image={Silke}
            name="Silke Knoll"
            instruments="Kurse, Musiktherapie, Musikschulleitung"
            vita="Ab 1995 Tätigkeit als Musikpädagogin im Bereich Musizieren mit Babys, Kleinkindern und deren Eltern sowie Musikgarten II nach Hohner (ab 3 Jahren ) 1997 Seminar bei Prof. Cramer (Berlin) “Musik mit behinderten Menschen“ 2002- 2006 pädagogisches Studium an der Fachhochschule Fulda mit Abschluss als Diplom Sozialpädagogin mit staatlicher Anerkennung, Studienschwerpunkt Musik im Integrationsbereich Diplomarbeit mit dem Titel: „Förderung durch Musik und Bewegung bei Kleinkindern dargestellt am Beispiel einer Eltern-Kind-Gruppe“ seit 1996 Dozentin und Leitung der Musikschule “musik total“ 2005 Produzentin eigener Kinderlieder und Kindergeschichten, Musicals."
          />
          <LehrerBlock
            image={Holgi}
            name="Holger Roth"
            instruments="Keyboard, Klavier, Melodika"
            vita="Berufs-Musiker seit 1984. Tontechnik-Studium an der School of entertainment and technology, Köln. Live-Auftritte derzeit u.a. mit Backround und Vier Gewinnt."
          />
          <LehrerBlock
            image={Robin}
            name="Robin Mittenzwei"
            instruments="Schlagzeug"
            vita=""
          />
          <LehrerBlock
            image={Thomas}
            name="Thomas Bopp"
            instruments="Gesang"
            vita='Der charismatische Sänger aus dem Rhein-Main-Gebiet erhielt seine künstlerische Ausbildung an der Stage School Hamburg, sowie bei Jane Comerford (Texas Lightning) und Amy Leverenz.
            Er ist Gewinner der "Fame Academy" (RTL2), war Mitglied der Band "BECOME ONE" und hatte Auftritte in Shows wie The Dome, Top of the Pops, MTV, RTL, ARD, MDR.
            Im Laufe seiner Karriere stand er schon mit Ronan Keating, Sarah Connor, Ricky Martin gemeinsam auf der Bühne.'
          />
          <LehrerBlock
            image={Anna}
            name="Anna Busch"
            instruments="Klavier, Akkordeon, Melodika"
            vita="Geboren 1960, vierjährige Ausbildung zur Musikpädagogin. Danach Lehrerin für Musik an einer allgemeinbildenden Schule in Duschanbe. Seit 1990 Freiberufliche Musiklehrerin für Klavier, Melodika und Musikalische Früherziehung."
          />
          <LehrerBlock
            image={Holger}
            name="Holger Böhme"
            instruments="Keyboard, Klavier"
            vita="Seit 1993 Diplomierter Keyboardlehrer. Studium an der Fachschule für Tasteninstrumente Riva San Vitale. Weiterbildung an der Rhön-Akademie Schwarzerden in den Bereichen „Klangmassage, Förderung des Lern- und Spielverhaltens, ADS/ADHS, Unterstützung bei Störungen der Wahrnehmungsfähigkeit“. Als Live-Musiker ist er u.a. mit Soulfire, You&I, Manhatten Six, Ten of a kind, Tower of Power und vielen Anderen unterwegs.
             "
          />
          <LehrerBlock
            image={Janina}
            name="Janina Kempel"
            instruments="Windel- und Zwergenrockerkurse"
            vita=""
          />
          <LehrerBlock
            image={Klaus}
            name="Klaus Ellenbrand"
            instruments="Schlagzeug"
            vita=""
          />
          <LehrerBlock
            image={David}
            name="David Neuendorf"
            instruments="Schlagzeug, Cajon"
            vita=""
          />
          <LehrerBlock
            image={Martin}
            name="Martin Fink"
            instruments="Gitarre, Bass, Instrumentenkarussell"
            vita="
            Seit 1999 ca.700 Auftritte mit etlichen Bands (Helium6, Leon Taylor, Ardillita, La Tribuu,…)
            verschiedener Musikrichtungen in ganz Deutschland, inklusive Studio-  und Fernsehproduktionen
            (ZDF-Fernsehgarten, Vorentscheid zum Grand Prix d‘Eurovision, ...)
            Ausbildung an der FMW-Frankfurter Musikwerkstatt.
            2008 Abschluss zum stattlich anerkannten 
            Berufsmusiker und Instrumentalpädagogen für Jazz- und Popularmusik.
            Seit 2007 Als Lehrer für Gitarre (E-Gitarre/Akustikgitarre/Konzertgitarre),
            E-Bass, und Keyboard/Klavier tätig."
          />
          <LehrerBlock
            image={Jessica}
            name="Jessica Dürrmann"
            instruments="Kurse, Gesang, Klarinette, Saxophon"
            vita=""
          />
          <LehrerBlock
            image={Nadiia}
            name="Tom Wegmann"
            instruments="Gitarre"
            vita=""
          />
        </Grid>
      </Section>
    </div>
  );
};

export default UnserTeam;
