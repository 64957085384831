import React from "react";
import Hero from "../../atoms/Hero/Hero";
import Section from "../../atoms/Section/Section";
import SocialMediaBar from "../../molecules/SocialMediaBar/SocialMediaBar";
import Footer from "../../organisms/Footer/Footer";

const MusikschulOrdnung = () => {
  return (
    <div>
      <Hero>Musikschulordnung</Hero>
      <Section>
        <h3> 1. Rechtsstellung</h3>
        <p>
          Die Musikschule Musik Total ist eine Musikschule in privater
          Trägerschaft mit staatlicher Anerkennung.
        </p>
        <h3>2. Aufgaben</h3>
        <p>
          Die Musikschule stellt sich der Aufgabe der musikalischen Ausbildung
          von Kindern, Jugendlichen und Erwachsenen sowie der
          Hochschulvorbereitung.
        </p>
        <h3>3. Unterrichtsangebot und Organisation</h3>
        <h4>3.1 Unterrichtsangebot</h4>
        <h4>
          3.1.1 Musizieren in Eltern-Kind- und Familien Gruppen (ab 6 Monate bis
          6 Jahre)
        </h4>
        <h4>3.1.2 Musikalische Frühbildung (ab 4,5 Jahre bis 6 Jahre)</h4>
        <p>
          In einem für dieses Alter ganzheitlich entwickelten Lernprogramm
          werden musikalische Anreize geschaffen, die als Grundlage für die
          Vorbereitung des Instrumentalunterrichts dienen.
        </p>
        <p>
          Inhalte: Singen und Sprechen Musik erleben und “begreifen“ <br />{" "}
          Elementares Instrumentalspiel Musik und Bewegung
          <br /> Musikhören Instrumentenkunde <br />
          Musiklehre Wahrnehmungsförderung
        </p>
        <p>
          Die Zahl der Schüler*innen ist abhängig davon, ob es sich um eine
          Integrative-, Heilpädagogische oder Regelgruppe handelt. Die erste
          Stunde gilt als Schnupperstunde.
        </p>
        <h4>3.1.3 Instrumental- und Vokalunterricht</h4>
        <p>
          Der Unterricht wird in Form von Einzel- oder Kleingruppenunterrichts
          erteilt. Die Gruppenstärke richtet sich nach den fachlichen und
          organisatorischen Notwendigkeiten.
        </p>
        <h4>3.1.4 Ergänzungsfächer</h4>
        <p>
          Neben dem Instrumentalunterricht werden Begleitfächer eingerichtet.
          Dies sind musiktheoretische Kurse, das Musizieren im Ensemble und der
          Instrumenten-Schnupperclub für Grundschulkinder.
        </p>
        <h4> 3.2 Unterrichtsorganisation</h4>
        <h4>3.2.1 Unterrichtsstätten</h4>
        <p>
          Der Unterricht findet sowohl in den Unterrichtsräumen der Musikschule
          als auch in öffentlichen Einrichtungen, wie Kindergärten und
          Kindertagestätten, sowie in Grundschulen statt.
        </p>
        <h4>3.2.2 Unterrichtszeiten im Festvertrag</h4>
        <p>
          Das Schuljahr beginnt am 01. August und endet am 31. Juli des
          Folgejahres. Es werden maximal 36 Unterrichtseinheiten der in Ziffer
          3.2.3 genannten Dauer abgedeckt. Diese erschließen sich anhand der
          Feiertage und beweglichen Ferientage. Der Unterricht findet nur an
          Werktagen statt. An gesetzlichen Feiertagen, Brückentagen, Sonntagen
          und während der Ferien wird nur nach Absprache unterrichtet. Maßgebend
          ist die Ferienordnung des Landes Hessen. Der/Die Schüler*in ist zur
          pünktlichen und regelmäßigen Teilnahme am Unterricht verpflichtet.
        </p>
        <h4> 3.2.3 Unterrichtsdauer</h4>
        <p>
          Windelrocker 30 Minuten - Zwergenrocker 30 Minuten
          <br />
          Sandkastenrocker 30 Minuten - Musikalische Früherz. 30 Minuten
          <br />
          Ergänzungsfächer 45 Minuten - Kinderkurse Zusatzangebote 45 Minuten
          <br />
          Instrumental-, Einzel- oder Gruppenunterricht 30/45 Minuten
          <br />
        </p>
        <h4>3.2.4 Lehrmittel</h4>
        <p>
          Die Beschaffung der Lehrmittel und der Instrumente ist grundsätzlich
          Sache der Schüler*innen. Die Lehrpersonen beraten gerne bei der
          Anschaffung.
        </p>
        <h3>4. Zulassungs- und Abgangsbestimmungen</h3>
        <h4>4.1 Anmeldung</h4>
        <p>
          Die Aufnahme eines Schülers / einer Schülerin erfolgt mit Abgabe der
          schriftlichen Anmeldung vor der Probestunde. Dazu ist das
          entsprechende Online-Formular zu benutzen. Kommt es nach der
          gebührenpflichtigen Probestunde zu keinem Vertrag, werden alle
          vorliegenden Daten gelöscht.
        </p>
        <h3>4.2 Kündigungen</h3>
        <p>
          4.2.1 Kündigungen sind in den Gruppen nur zum Semesterende 31. Januar
          und 31. Juli möglich. Einzelunterricht ist in den ersten vier Monaten
          nicht kündbar. Nach diesen vier Monaten verkürzt sich die
          Kündigungsfrist auf mindestens einen Monat zum nächsten Monatsende.
          (Soll der Vertrag z.B. zum 31.08. enden, muss die Kündigung bis zum
          31.07. vorliegen.) Bei Kündigung vor den Ferien und Wiedereinstieg
          nach den Ferien werden die Gebühren rückwirkend berechnet.
        </p>
        <p>
          4.2.2 Kündigungen bedürfen der schriftlichen Form (Brief oder E-Mail)
          und sind an die Schulleitung zu richten.
        </p>
        <h3>4.3 Ausschluss und Hygienebestimmungen</h3>
        <p>
          4.3.1 Bei unregelmäßigem Unterrichtsbesuch kann ein*e Schüler*in vom
          Unterricht ausgeschlossen werden. Dies kann ebenso erfolgen, wenn die
          Zahlung der Gebühren verweigert wird.
        </p>
        <p>
          4.3.2 Bei offensichtlicher Erkrankung ist die Lehrkraft berechtigt,
          den Unterricht nicht zu erteilen.
        </p>
        <p>
          4.3.3 Je nach Infektionslage ist es den Dozent*innen freigestellt, das
          Tragen einer FFP2-Maske zu verlangen. In den Räumen der Musikschule
          gelten die jeweils offiziellen Hygienebestimmungen des Landes Hessen.
        </p>
        <h3> 5. Aufsicht</h3>
        <p>Eine Aufsicht besteht nur für die Dauer der Unterrichtseinheit. </p>
        <h3>6. Gebühren</h3>
        <h4>6.1 Höhe der Gebühren</h4>

        <h5> Instrumental und Gesang</h5>
        <p>
          Einzelunterricht 30 Min. p. Wo. € 75,00/Monat
          <br />
          Einzelunterricht 45 Min. p. Wo. € 112,00/Monat
          <br />
          Gruppenunterricht 2er 30 Min. p. Wo. € 63,00/Monat
          <br />
          Gruppenunterricht 2er 45 Min. p. Wo. € 68,00/Monat
          <br />
          Gruppenunterricht 3er 45 Min. p. Wo. € 62,00/Monat
          <br />
        </p>
        <h5> Band</h5>
        <p>
          Bandgebühren für unsere Schüler 60 Min./Woche € 40,00/Monat
          <br />
          Bandgebühren für externe Schüler 60 Min./Woche € 45,00/Monat
          <br />
        </p>
        <p>
          6.2 Die monatliche Gebühr ist während des gesamten Unterrichtsjahres
          (12 monatliche Beiträge) zu bezahlen. Damit werden maximal 36
          Unterrichtseinheiten abgedeckt.
        </p>
        <p>
          6.2.1 Im Krankheits- oder Verhinderungsfall besteht kein Anspruch auf
          Befreiung von der Zahlungspflicht.
        </p>
        <p>
          6.2.2 Unterrichtsstunden, die in Folge des Dozenten / der Dozent*in
          ausfallen, werden nachgeholt oder von einem Fachlehrer vertreten. Ist
          das nicht möglich, wird die Gebühr zurückerstattet. Der/Die Lehrer*in
          verpflichtet sich jeweils drei verschiedene Termine für die
          ausgefallene Stunde anzubieten. Nimmt der/die Schüler*in keinen dieser
          Termine an, verfällt die Stunde.
        </p>
        <h3>7. Blockunterrichte/Prepaid-Karten</h3>
        <h4>7.1 Kosten Prepaid-Karten</h4>
        <p>
          Instrumental und Gesang
          <br />
          10er Karte 30 Min.: 300,- €; 10er Karte 45 Min.: 420,- €; 10er Karte
          60 Min.: 570,- €;
          <br />
          5er Karte 30 Min.: 150,- €; 5er Karte 45 Min.: 225,- €; 5er Karte 60
          Min.: 300,- €
        </p>
        <p>Gruppenunterricht Prepaid auf Anfrage.</p>

        <p>
          7.2 Die Einheiten werden individuell und einzeln mit den Dozent*innen
          vereinbart.
        </p>
        <p>
          7.3 Im Krankheits- oder Verhinderungsfall muss der/die Dozent*in 24
          Stunden im Voraus informiert werden.
        </p>
        <p>
          7.4 Der Kauf einer Prepaid-Karte ist nur über das entsprechende
          Online-Anmeldeformular möglich.
        </p>
        <p>
          7.5 Die Gültigkeitsdauer beträgt bei 10er Karten 6 Monate und bei 5er
          Karten 3 Monate. Nach Absprache mit den Dozent*innen kann die
          Gültigkeitsdauer verlängert werden.
        </p>
        <p>
          Die Musikschulordnung tritt in dieser Fassung zum 01.10.2022 in Kraft.
        </p>
      </Section>
      <SocialMediaBar />
      <Footer />
    </div>
  );
};

export default MusikschulOrdnung;
