import React from "react";
import styled from "styled-components";
import { variables } from "../../../data/variables";

const Wrapper = styled.section`
  background: ${(props) => variables.background[props.theme || "white"]};
  color: ${(props) => variables.text[props.theme || "white"]};
  display: flex;
  position: relative;
  justify-content: center;
  @media (min-width: ${variables.breakpoints.m}px) {
    padding: 3rem 4rem;
  }
`;

const ClampedContent = styled.div`
  max-width: 1200px;
  width: 100%;
  padding: 2rem 1rem;
`;

const Line = styled.div`
  position: absolute;
  bottom: 0.5rem;
  width: 100%;
  height: 0.5rem;
  background: ${variables.text.primary};
`;

const Section = (props) => {
  return (
    <Wrapper theme={props.theme} style={props.style} id={props.id}>
      {props.noClamp ? (
        props.children
      ) : (
        <ClampedContent>{props.children}</ClampedContent>
      )}
      {props.lineBottom && <Line theme={props.theme} />}
    </Wrapper>
  );
};

export default Section;
