import React from "react";
import Section from "../../atoms/Section/Section";
import styled from "styled-components";
import { variables } from "../../../data/variables";
import TestimonialBlock from "../TestimonialBlock/TestimonialBlock";

import Andi from "../../../static/img/lehrer/andi_knoll.jpg";
import Dennis from "../../../static/img/lehrer/dennis_korn.jpg";
import Felix from "../../../static/img/lehrer/felix_rogge.jpg";
import Sebastian from "../../../static/img/lehrer/sebastian_eichenauer.jpg";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  grid-gap: 1rem 1rem;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`;

const TestimonialsSection = props => {
  const {} = props;
  return (
    <Section>
      <Grid>
        <TestimonialBlock
          image={Andi}
          name="Paul Gilbert"
          text="Ohne Musik Total wäre ich wahrscheinlich Klemptner geworden."
        />
        <TestimonialBlock
          image={Felix}
          name="Peter Fox"
          text="Vor Musik Total habe ich Texte für Helene Fischer geschrieben. Jetzt bin ich frei."
        />
        <TestimonialBlock
          image={Sebastian}
          name="George Clooney"
          text="Ich mach zwar keine Musik aber der Kaffee dort ist sehr lecker."
        />
      </Grid>
    </Section>
  );
};

export default TestimonialsSection;
