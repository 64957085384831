import React from "react";
import styled from "styled-components";
import Section from "../../atoms/Section/Section";
import Hero, { Heading } from "../../atoms/Hero/Hero";

const PresseEl = styled.a`
  opacity: 0.4;
  font-size: 2rem;
  font-variant: small-caps;
  font-weight: bold;
  margin: 1rem;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;

const data = [
  {
    title: "Hessenschau",
    url:
      "https://www.hessenschau.de/tv-sendung/regenbogen-song-aus-gelnhausen,video-121200.html",
  },
  {
    title: "RTL Hessen",
    url:
      "https://www.rtl-hessen.de/beitrag/gelnhausen-silke-knoll-komponiert-regenbogensong",
  },
  {
    title: "Kinzig News",
    url:
      "https://kinzig.news/4949/word-rainbow-song-bald-auch-in-gebaerdensprache",
  },
  {
    title: "GNZ",
    url:
      "https://www.gnz.de/region/gelnhausen/anfragen-aus-zahlreichen-landern-EA1367732",
  },
];

export const Presse = () => {
  return (
    <>
      <Section>
        <Heading>Der World Rainbow Song in der Presse</Heading>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(50px, 250px))",
            marginTop: "2rem",
          }}
        >
          {data.map((d) => {
            return (
              <PresseEl href={d.url} target="_blank">
                {d.title}
              </PresseEl>
            );
          })}
        </div>
      </Section>
    </>
  );
};
