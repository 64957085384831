import Section from "../../atoms/Section/Section";
import styled from "styled-components";
import React from "react";
import { variables } from "../../../data/variables";

const Container = styled.div`
  display: grid;
  grid-template-columns: 300px auto;
  grid-template-rows: 1fr minmax(20rem, auto) auto;
  margin-bottom: 2rem;
  @media (max-width: ${variables.breakpoints.m}px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 90vw auto auto;
  }
`;
const Date = styled.div`
  font-size: 2rem;
  color: ${variables.text.primary};
  font-weight: bold;
`;
const Day = styled.div`
  color: ${variables.text.primary};
`;
const Title = styled.div`
  font-size: 2rem;
  background: ${variables.background.primary};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
`;
const SubTitle = styled.div`
  color: ${variables.text.secondary};
`;
const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const Content = styled.div`
  padding: 2rem;
  color: ${variables.text.secondaryLight};
  background: ${variables.background.secondaryLight};
`;
const Dozent = styled.div`
  padding: 2rem;
  background: ${variables.background.primary};
  color: ${variables.text.primary};
  font-weight: bold;
`;
const DozentDesc = styled.div`
  padding: 2rem;
`;

export const Workshop = (props) => {
  const {
    date,
    day,
    title,
    subTitle,
    description,
    dozent,
    dozentDescription,
    img,
  } = props;
  return (
    <Container>
      <div
        style={{ padding: "2rem", background: variables.background.primary }}
      >
        <Date>{date}</Date>
        <Day>{day}</Day>
      </div>
      <div style={{ padding: "2rem" }}>
        <Title>{title}</Title>
        <SubTitle>{subTitle}</SubTitle>
      </div>
      <Img src={img} />
      <Content>{description}</Content>
      <Dozent>{dozent}</Dozent>
      <DozentDesc>{dozentDescription}</DozentDesc>
    </Container>
  );
};
