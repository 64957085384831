import React from "react";
import Hero from "../../atoms/Hero/Hero";
import Section from "../../atoms/Section/Section";
import SocialMediaBar from "../../molecules/SocialMediaBar/SocialMediaBar";
import Footer from "../../organisms/Footer/Footer";
import CTA from "../../molecules/CTA/CTA";
import styled from "styled-components";
import { IconNote1 } from "./../../atoms/Icon/IconNote1";
import KarussellBg from "../../../static/img/karussellBg.jpg";

const Instrument = styled.li`
  list-style: none;
  display: flex;
  margin: 2rem 0;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
  svg {
    margin-bottom: 1rem;
  }
  :hover {
    filter: brightness(120%);
  }
`;

const List = styled.ul`
  display: grid;
  padding: 0;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
`;

class Instrumentenkarussell extends React.Component {
  state = { open: undefined };

  setOpen = (type) => {
    // this.state.open === type
    //   ? this.setState({ open: undefined })
    //   : this.setState({ open: type });
  };

  render() {
    return (
      <div>
        <Hero image={KarussellBg}>Instrumentenkarussell</Hero>
        <Section>
          <p>
            {" "}
            Juhu, ich lerne ein Instrument! Bloß welches? Ob Groß oder Klein,
            nicht jeder weiß von Anfang an, welches Instrument für ihn das
            richtige ist. Ein Tasten- oder doch lieber ein Saiteninstrument?
            Schlagzeug, Bass oder darf’s ordentlich Gebläse sein? Bei uns könnt
            ihr in aller Ruhe testen, was zu euch am besten passt!
          </p>
          <p>
            Das „Instrumentenkarussell“ ist ein Kurs über 12 Einheiten a 45
            Minuten. In dieser Zeit könnt ihr verschiedene Instrumente für eine
            gewisse Zeit ausprobieren und erhaltet zu jedem Instrument eine
            kleine Einführung. Also eine tolle Gelegenheit, viele
            unterschiedliche Instrumente kennen- und eines davon ganz besonders
            lieben zu lernen.
          </p>{" "}
        </Section>

        <Section theme="secondaryLight">
          Das Instrumentenkarussell ist ein Angebot speziell für Kinder im
          Vorschulalter (5-6 Jahre).
        </Section>
        <CTA
          text="Finde dein Instrument"
          theme="primary"
          button={
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconNote1 theme="primary" size="1.5rem" />
              <span style={{ margin: "0 .5rem" }}>Jetzt Anmelden</span>
            </div>
          }
        />
        <SocialMediaBar />
        <Footer />
      </div>
    );
  }
}

export default Instrumentenkarussell;
