import * as React from 'react';
import { Icon } from '.';

export const IconSnare: React.SFC<{}> = props => {
  return (
    <Icon
      d="M10,3c0-1.65,1.35-3,3-3c0.21,0,0.41,0.061,0.58,0.189c3.55,2.531,6.41,4.61,8.74,6.33c-1.46,1.07-2.65,1.971-3.63,2.711 c-2.29-1.471-5.01-3.25-8.24-5.4C10.17,3.65,10,3.33,10,3z M2,42h11v-2h-2V29h2v-2H2V42z M13,38h2v-7h-2V38z M15,40v2h18v-2h-2V29h2 v-2H15v2h2v11H15z M33,38h2v-7h-2V38z M35,40v2h11V27H35v2h2v11H35z M45,21h-7.659c0.134-0.084,0.257-0.181,0.369-0.29 c0.57-0.58,0.79-1.38,0.59-2.21c-0.34-1.439-1.83-2.73-3.979-3.45c-1.34-0.44-1.34-0.44-3.46-2.09c-0.45-0.351-0.99-0.771-1.66-1.29 c-1.22,0.78-2.311,1.46-3.28,2.061c1.13,0.68,2,1.199,2.68,1.59c2.221,1.289,2.221,1.289,2.45,2c0.627,1.871,1.922,3.104,3.248,3.68 H13.694c1.328-0.572,2.628-1.805,3.254-3.683c0.236-0.704,0.236-0.704,2.448-1.999c2.478-1.451,7.634-4.471,18.158-11.486 C37.833,3.646,38,3.334,38,3c0-1.654-1.346-3-3-3c-0.208,0-0.412,0.064-0.581,0.186C23.902,7.698,19.332,11.252,17.136,12.961 c-2.115,1.645-2.115,1.645-3.453,2.09c-2.157,0.72-3.648,2.01-3.988,3.451c-0.195,0.826,0.023,1.631,0.598,2.205 c0.111,0.111,0.235,0.209,0.368,0.293H3c-1.65,0-3,1.35-3,3v1h48v-1C48,22.35,46.65,21,45,21z M0,45c0,1.65,1.35,3,3,3h42 c1.65,0,3-1.35,3-3v-1H0V45z"
      {...props}
    />
  );
};
