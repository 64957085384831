import React from "react";
import Hero from "../../atoms/Hero/Hero";
import Section from "../../atoms/Section/Section";
import Footer from "../../organisms/Footer/Footer";
import SocialMediaBar from "../../molecules/SocialMediaBar/SocialMediaBar";
import LaufendeKurseSection from "../../molecules/LaufendeKurseSection/LaufendeKurseSection";
import PicTextBlock from "./../../molecules/PicTextBlock/PicTextBlock";
import KursTabelle from "../../organisms/KursTabelle/KursTabelle";
import Tabelle from "../../molecules/Tabelle/Tabelle";
import { KursInfo } from "./kursinfo";
import CTA from "./../../molecules/CTA/CTA";

const KursTemplate = props => {
  return (
    <div>
      <Hero>{props.title}</Hero>
      <Section theme="white" style={{ paddingTop: 0 }}>
        <PicTextBlock img={props.polaroid} title={props.age}>
          {props.children}
        </PicTextBlock>
      </Section>
      <LaufendeKurseSection />
      {/* <Section>
        <Tabelle
          title="Kurse in Gelnhausen"
          color={KursInfo.color}
          header={KursInfo.header}
          drop={KursInfo.drop}
          columnCount={KursInfo.columnCount}
          rows={KursInfo.rows}
        />
        <Tabelle
          title="Kurse in Bad-Soden"
          color="blue"
          header={KursInfo.header}
          drop={KursInfo.drop}
          columnCount={KursInfo.columnCount}
          rows={KursInfo.rows}
        />
      </Section> */}
      <CTA
        text="Kursanmeldung"
        // theme="primary"
        button={
          <div style={{ display: "flex", alignItems: "center" }}>Anmeldung</div>
        }
      />
      <SocialMediaBar />
      <Footer />
    </div>
  );
};

export default KursTemplate;
