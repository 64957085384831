import React from "react";
import Hero from "../../atoms/Hero/Hero";
import Section from "../../atoms/Section/Section";
import SocialMediaBar from "../../molecules/SocialMediaBar/SocialMediaBar";
import Footer from "../../organisms/Footer/Footer";
import { variables } from "../../../data/variables";
import styled from "styled-components";

const Frame = styled.iframe`
  border: none;
  width: 99vw;
  height: 100rem;
  overflow-x: hidden;
`;
const Probestunde = () => {
  return (
    <div>
      {/* <Hero>Impressum</Hero> */}
      {/* <Section> */}
      <Frame
        src="https://www.axinio.app/booking/forms/trial/53026260-0d3a-490f-a77a-c797b7376889/?source=Webseite"
        id="probestunde"
      />
      {/* </Section> */}
      <SocialMediaBar />
      <Footer />
    </div>
  );
};

export default Probestunde;
