import React from "react";
import Hero from "../../atoms/Hero/Hero";
import Section from "../../atoms/Section/Section";
import Footer from "../../organisms/Footer/Footer";
import SocialMediaBar from "../../molecules/SocialMediaBar/SocialMediaBar";
import SandkastenRockerImg from "../../../static/img/sandkastenrocker.jpg";
import BandBlock from "../../molecules/BandBlock/BandBlock";
import LaufendeKurseSection from "../../molecules/LaufendeKurseSection/LaufendeKurseSection";
import KursTemplate from "../../templates/KursTemplate/KursTemplate";

const SandkastenRocker = () => {
  return (
    <KursTemplate
      title="Die Sandkastenrocker"
      image={SandkastenRockerImg}
      polaroid={SandkastenRockerImg}
      age="Alter: 3 - 4 1/2 Jahre"
    >
      <p>
        Hallo Sandkastenrocker! Hier heißt es aufpassen und zuhören. Fetzige
        Musik gibt es, die die Beine wippen lässt. Kurze Klanggeschichten
        fördern die Konzentration. Fingerspiele regen das Sprachzentrum an. Der
        Einsatz verschiedener Orff'scher Instrumente macht mit unterschiedlichen
        Tönen und Klängen vertraut.
      </p>
      <p>
        Wir singen laut und leise, wir toben und tanzen, mal schnell und mal
        langsam. Wir kuscheln aber auch noch ganz gerne und lassen uns von
        klassischer Musik faszinieren. Hier passiert so viel! Schaut es euch
        einfach mal an.
      </p>
    </KursTemplate>
  );
};

export default SandkastenRocker;
