import React from "react";
import styled from "styled-components";
import Section from "../../atoms/Section/Section";
import Bild1 from "../../../static/bild1.png";
import Bild2 from "../../../static/bild2.png";
import Bild3 from "../../../static/bild3.png";
import { Link } from "react-router-dom";
import { variables } from "../../../data/variables";
import { fetchNews } from "../../../helper/fetchContent";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { history } from "../../../App";

const Item = styled.div`
  height: 30rem;
  background: ${variables.background.white};
  color: ${variables.text.white};
  display: grid;
  box-shadow: 0px 0.5rem 1rem rgba(0, 0, 0, 0.2);
  grid-template-rows: 50% 1fr 2rem;
  cursor: pointer;
`;
const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  grid-gap: 1rem 1rem;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`;
const Picture = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  padding: 0;
  flex: 1;
  /* height: 17rem; */
`;
const Content = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 1rem 1.5rem;
  max-height: 100%;
  overflow: hidden;
  flex: 1;
`;
const Date = styled.div`
  color: ${variables.text.secondary};
  font-size: 0.8rem;
  margin: 0;
  padding: 0;
`;
const Heading = styled.div`
  font-weight: bold;
  margin-bottom: 0.2rem;
`;
const Text = styled.div`
  line-height: 1rem;
  font-size: 0.9rem;
  overflow: hidden;
`;
const Gradient = styled.div`
  position: absolute;
  bottom: 1rem;
  left: 0;
  right: 0;
  z-index: 99;
  height: 5rem;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
`;

const More = styled.span`
  text-decoration: underline;
  font-size: 0.9rem;
  display: flex;
  padding: 0 0 1rem 1.5rem;
  align-content: flex-end;
`;

const News = () => {
  const [newsData, setNews] = React.useState([]);
  React.useEffect(() => {
    const f = async () => {
      const d = await fetchNews();
      setNews([...d, false, false, false]);
    };
    f();
  }, []);

  return (
    <Section theme="primary" noClamp>
      <Wrapper>
        {newsData.slice(0, 4).map((e) => {
          if (!e)
            return (
              <div
                style={{
                  background: "white",
                  opacity: 0.2,
                  boxShadow: "0px 0.5rem 1rem rgba(0, 0, 0, 0.2)",
                }}
              />
            );
          return (
            <Item onClick={() => history.push(`/news/${e.id}/`)}>
              <Picture src={e.img} />
              <Content>
                <Date>{e.date}</Date>
                <Heading>{e.title}</Heading>
                <Text>{documentToReactComponents(e.inhalt)}</Text>
                <Gradient />
              </Content>
              <More>Mehr</More>
            </Item>
          );
        })}
        {/* <Item>
          <Picture src={Bild1} />
          <Content>
            <Date>04.06.2017</Date>
            <Heading>Andre News</Heading>
            <Text>
              Hachja de Andre. Schon en putziger Kerl. Immer en freche Spruch
              uff de Lippe abbä man muss ihn einfach Lieb haben. Hier sitzt er
              in der Sonne und hat die Gudrum noch im Gepäck. Da halte se Ihre
              Gitarre fest weil es mal wieder kaane Ständer gibt, sonst fällt
              die um, und lesen zusammen die Bild Zeitung.
            </Text>
          </Content>
          <More>
            <Link to="/">Mehr</Link>
          </More>
        </Item>
        <Item>
          <Picture src={Bild2} />
          <Content>
            <Date>04.06.2017</Date>
            <Heading>Warum liegt hier überhaupt Stroh?</Heading>
            <Text>
              Filmklassiker, nachgespielt in der Musikschule. Das volle Video
              gibt es demnächst im Netz.
            </Text>
          </Content>
          <More>
            <Link to="/">Mehr</Link>
          </More>
        </Item>
        <Item>
          <Picture src={Bild3} />
          <Content>
            <Date>04.06.2017</Date>
            <Heading>Kinder lachen MO8 aus</Heading>
            <Text>
              Schon die kleine Lutzi weiss: So ein Mo8, der kann nix. Auch wenn
              der Lehrer sie versucht vom Gegenteil zu überzeugen, manche Sachen
              sind einfach klar wie Kloßbrühe. Deshlab beukottiert sie wie auf
              diesem Bild zu sehen das Klavierspiel.
            </Text>
          </Content>
          <More>
            <Link to="/">Mehr</Link>
          </More>
        </Item>
        <Item>
          <Picture src={Bild1} />
          <Content>
            <Date>04.06.2017</Date>
            <Heading>Schu widder de Andre</Heading>
            <Text>
              Es reicht einfach net, oder? Soooo ein schönes Bild, des könnt ich
              de ganze Tach angugge. Da wed me jedes ma ganz warm um buttrige
              Herzen. Schee. Gude Tag noch.
            </Text>
          </Content>
          <More>
            <Link to="/">Mehr</Link>
          </More>
        </Item> */}
      </Wrapper>
    </Section>
  );
};

export default News;
