import React from "react";
import styled from "styled-components";
import { pages } from "../../../data/navigation";
import { variables } from "../../../data/variables";
import { Link } from "react-router-dom";
import { pageToUrl } from "../../../helper/pageToUrl";
import { useState } from "react";
import LogoImage from "../../../static/mtlogo grad@2x.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import RainbowImage from "../../../static/img/rainbow/menu_header.png";

const Wrapper = styled.div`
  height: ${variables.sizes.navbar};
`;

const Bar = styled.div`
  height: ${variables.sizes.navbar};
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
  padding: 0 1.5%;
  background: ${variables.background.white};
  color: ${variables.text.primary};
  z-index: 500;
  border-bottom: 1px solid ${variables.background.secondaryLight};
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
`;

const Drawer = styled.div`
  position: fixed;
  background-color: ${variables.background.white};
  padding: 2rem;
  transition: all 150ms ease-in-out;
  box-shadow: 0 0 2rem rgba(0, 0, 0, ${(props) => (props.open ? ".2" : "0")});
  transform: translateX(${(props) => (props.open ? "0" : "-80vw")});
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 5000;
  height: 100vh;
  width: 80vw;
  overflow-x: scroll;
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 600;
  background-color: rgba(0, 0, 0, 0.4);
`;

const Logo = styled.img`
  height: 2.2rem;
  margin: 0 0.5rem;
  object-fit: contain;
`;

const PrimaryItem = styled.div`
  white-space: nowrap;
  font-size: 0.9rem;
  font-weight: bold;
  height: 2rem;
  flex: 1;
  display: inline-flex;
`;

const SecondaryItem = styled(PrimaryItem)`
  font-weight: normal;
`;
const SubPage = styled(PrimaryItem)`
  font-weight: normal;
  padding-left: 1rem;
`;

const RainbowHeader = styled.img`
  height: 3.5rem;
  margin-left: auto;
`;

const MobileNavbar = (props) => {
  const [drawerOpen, setDrawer] = useState(false);

  return (
    <Wrapper>
      <Bar>
        <FontAwesomeIcon
          icon={faBars}
          size="lg"
          color={variables.text.secondary}
          style={{ margin: ".6rem" }}
          onClick={() => setDrawer(true)}
        />
        <Link to="/" style={{ paddingTop: 6 }}>
          <Logo src={LogoImage} />
        </Link>
        <Link to="/world-rainbow-song" style={{ paddingTop: 6 }}>
          <RainbowHeader src={RainbowImage} />
        </Link>
      </Bar>
      <Drawer open={drawerOpen}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Logo
            src={LogoImage}
            style={{ marginBottom: "1rem" }}
            onClick={() => setDrawer(false)}
          />
          {pages.map((page) => {
            if (page.primary) {
              return (
                <div
                  key={page.name}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Link
                    to={page.home ? pageToUrl(page.name) : "/"}
                    onClick={() => setDrawer(false)}
                  >
                    <PrimaryItem>{page.name}</PrimaryItem>
                  </Link>
                  {page.pages &&
                    page.pages.map((subPage) => {
                      return (
                        <Link
                          key={subPage[0]}
                          to={pageToUrl(subPage[0])}
                          onClick={() => setDrawer(false)}
                        >
                          <SubPage>{subPage[0]}</SubPage>
                        </Link>
                      );
                    })}
                </div>
              );
            }
            return null;
          })}
          {pages.map((page) => {
            if (!page.primary) {
              let pages = [];

              if (page.ext) {
                pages.push(
                  <a href={page.ext}>
                    <SecondaryItem>{page.name}</SecondaryItem>
                  </a>
                );
              } else {
                pages.push(
                  <Link
                    key={page.name}
                    to={page.home ? pageToUrl(page.name) : "/"}
                    style={{
                      textDecoration: "none",
                      color: "unset",
                      height: "100%",
                    }}
                    onClick={() => setDrawer(false)}
                  >
                    <SecondaryItem>{page.name}</SecondaryItem>
                  </Link>
                );
              }
              if (page.pages) {
                page.pages.map((subpage) =>
                  pages.push(
                    <Link
                      key={subpage[0]}
                      to={pageToUrl(subpage[0])}
                      style={{
                        textDecoration: "none",
                        color: "unset",
                        height: "100%",
                      }}
                      onClick={() => setDrawer(false)}
                    >
                      <SecondaryItem>{subpage[0]}</SecondaryItem>
                    </Link>
                  )
                );
              }

              return [...pages];
            }
            return null;
          })}
        </div>
      </Drawer>
      {drawerOpen && <Backdrop onClick={() => setDrawer(false)} />}
    </Wrapper>
  );
};

export default MobileNavbar;
