export const variables = {
  background: {
    //  primary: "#d31027",;
    primary: "linear-gradient(to right,#e42b0e,#981558)",
    secondary: "#363636",
    secondaryLight: "#e5e5e5",
    white: "#FFFFFF",
    green: "#54974A",
    blue: "#4462AE"
  },
  text: {
    primary: "#FFFFFF",
    secondary: "#888888",
    secondaryLight: "black",
    white: "#000000",
    green: "#ffffff",
    blue: "#ffffff"
  },
  colors: {
    primary: "#d31027",
    red: "#d31027",
    green: "#54974A",
    blue: "#4462AE"
  },
  button: {
    background: {
      white: "#d31027",
      secondaryLight: "#363636",
      secondary: "#e5e5e5",
      primary: "#960b1c"
    },
    text: {
      white: "#FFFFFF",
      secondaryLight: "#FFFFFF",
      secondary: "#363636",
      primary: "#ffffff"
    }
  },
  sizes: {
    navbar: "3rem"
  },
  breakpoints: {
    s: 500,
    m: 800,
    l: 1200
  }
};
