import React from "react";
import styled from "styled-components";
import Avatar from "../../atoms/Avatar/Avatar";
import { variables } from "../../../data/variables";
import Button from "./../../atoms/Button/Button";

const Wrapper = styled.div`
  display: ${(props) => (props.visible ? "flex" : "none")};
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: white;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  @media (max-width: ${variables.breakpoints.m}px) {
    flex-direction: column;
  }
`;

const Name = styled.div`
  font-weight: bold;
  font-size: 1.5rem;
`;

const Image = styled.div`
  flex: 1;
  min-width: 80vh;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center top;
  background-image:url("${(props) => props.src}");
  @media (max-width: ${variables.breakpoints.m}px) {
    min-height: 70%;

  }
`;

const Instruments = styled.div`
  font-size: 0.9rem;
  opacity: 0.9;
`;

const BackButton = styled.div`
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
  :hover {
    color: ${variables.button.text.primary};
  }
`;

const Vita = styled.div`
  margin: 1rem 0;
`;

const Content = styled.div`
  flex: 1;
  position: relative;
  padding: 1rem 7rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: ${variables.breakpoints.m}px) {
    padding: 1rem 2rem;
  }
`;

const LehrerDetails = (props) => {
  const { image, name, instruments, vita, link, visible, close } = props;
  return (
    <Wrapper visible={visible}>
      <Image src={image} />
      <Content>
        <BackButton onClick={close}>Zurück</BackButton>
        <Name>{name}</Name>
        <Instruments>{instruments}</Instruments>
        <Vita>{vita}</Vita>
        {link && (
          <a href={link} target="blank">
            <Button>Zur Website</Button>
          </a>
        )}
      </Content>
    </Wrapper>
  );
};

export default LehrerDetails;
