import * as React from 'react';
import { Icon } from '.';

export const IconBass:  React.SFC<{}> = props  => {
  return (
    <Icon
      d="M46.567,4.36c0.37-0.28,0.68-0.4,0.71-0.41l0.529-0.18l0.131-0.551c0.079-0.39,0.319-1.74-0.36-2.59 C47.257,0.22,46.777,0,46.227,0h-0.229l-4.57,2.19l-0.76-0.9C40.487,1.11,40.237,1,39.957,1c-0.55,0-1,0.45-1,1 c0,0.28,0.12,0.53,0.3,0.71l0.391,0.33l-0.5,0.24l-0.141,0.4c-0.14,0.42-0.34,0.969-0.58,1.51l-0.76-0.9 C37.487,4.11,37.237,4,36.957,4c-0.55,0-1,0.45-1,1c0,0.28,0.12,0.53,0.3,0.71l1.33,1.13c-0.11,0.181-0.229,0.341-0.34,0.45 L15.256,28.41c-0.23-0.729,0.07-1.771,2.48-4.79l0.07-0.08c0.29-0.33,0.9-1.02,0.54-1.79c-0.16-0.34-0.52-0.76-1.41-0.729 c-3.3,0.049-5.67,2.629-7.92,8.629c-0.32,0.851-1.43,1.181-3.22,1.631c-2.47,0.6-5.84,1.439-5.84,5.72c0,1.09,0,4.41,3.29,7.71 C6.546,48,8.356,48,9.957,48c5.11,0,6.28-3.94,7.05-6.54c0.33-1.109,0.7-2.37,1.19-2.49c2.72-0.68,4.15-1.46,4.66-2.539 c0.3-0.621,0.37-1.521-0.05-2.041l-0.48-0.51l-0.61,0.15c-1.83,0.46-2.84,0.21-3.36-0.08L40.577,10.81c0.55-0.3,1.26-0.66,1.93-0.99 l0.74,0.881c0.18,0.18,0.43,0.3,0.71,0.3c0.55,0,1-0.45,1-1c0-0.28-0.11-0.53-0.29-0.71l-0.39-0.33 c0.069-0.04,0.109-0.061,0.109-0.061l0.57-0.27V8c0-0.95,0.21-1.67,0.49-2.24l0.8,0.94c0.18,0.18,0.43,0.3,0.71,0.3 c0.55,0,1-0.45,1-1c0-0.28-0.11-0.53-0.29-0.71L46.567,4.36z M4.247,40.71l2.42-2.42l3,3l-2.42,2.42L4.247,40.71z M9.957,45 c-0.55,0-1-0.45-1-1s0.45-1,1-1s1,0.45,1,1S10.506,45,9.957,45z M7.247,36.71l1.42-1.42l4,4l-1.42,1.42L7.247,36.71z M12.957,43 c-0.55,0-1-0.45-1-1s0.45-1,1-1s1,0.45,1,1S13.506,43,12.957,43z M13.247,38.71l-4-4l1.42-1.42l4,4L13.247,38.71z"
      {...props}
    />
  );
};
