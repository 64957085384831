import React from "react";
import Section from "./../../atoms/Section/Section";
import styled from "styled-components";
import { variables } from "../../../data/variables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem;
  color: ${variables.text.white};
  background: ${variables.background.white};
  :hover {
    background: ${variables.background.secondaryLight};
  }
`;

const SocialMediaBar = (props) => {
  return (
    <Section style={{ paddingTop: "2rem", paddingBottom: "2rem" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <a
          href="https://www.facebook.com/MusikschuleMusikTotal/"
          target="blank"
        >
          <FontAwesomeIcon
            icon={faFacebookF}
            size="2x"
            color={variables.text.secondary}
            className="faIcon"
          />
        </a>
        <a
          href="https://www.instagram.com/explore/locations/829073362/musikschule-musik-total-gelnhausen/"
          target="_blank"
        >
          <FontAwesomeIcon
            icon={faInstagram}
            size="2x"
            color={variables.text.secondary}
            className="faIcon"
          />
        </a>
        <a
          href="https://www.youtube.com/user/rumpelstilzchen64"
          target="_blank"
        >
          <FontAwesomeIcon
            icon={faYoutube}
            size="2x"
            color={variables.text.secondary}
            className="faIcon"
          />
        </a>
        <a href="mailto:janina@musik-total.net">
          <FontAwesomeIcon
            icon={faEnvelope}
            size="2x"
            color={variables.text.secondary}
            className="faIcon"
          />
        </a>
      </div>
    </Section>
  );
};

export default SocialMediaBar;
