import React from "react";
import Hero from "../../atoms/Hero/Hero";
import Section from "../../atoms/Section/Section";

import Footer from "../../organisms/Footer/Footer";
import SocialMediaBar from "../../molecules/SocialMediaBar/SocialMediaBar";
import BandBlock from "./../../molecules/BandBlock/BandBlock";
import Bonmuto from "../../../static/img/band/bonmuto.jpg";
import Lunaticmoon from "../../../static/img/band/lunaticmoon.jpg";
import LeavingFootprints from "../../../static/img/band/leaving-footprints.jpg";
import BandsBg from "../../../static/img/bandsBg.jpg";
import CTA from "./../../molecules/CTA/CTA";
import { IconTaperecorder } from "./../../atoms/Icon/IconTaperecorder";

const BandCoaching = () => {
  return (
    <div>
      <Hero image={BandsBg} style={{ backgroundPosition: "center center" }}>
        Unsere Musik Total Bands - bist du dabei?
      </Hero>
      <Section theme="secondaryLight">
        <p>
          Ohne Fleiß kein Preis? Ein wahrer Kern steckt schon in dieser
          Redensart. Aber mal ehrlich: Erst wenn man auch ein festes Ziel vor
          Augen hat, ist man doch motiviert genug, den notwendigen Fleiß auch
          aufzubringen. Stimmt's?
        </p>
        <p>
          Und genau dafür sind wir da: Musik Total bietet solche Ziele. Und die
          Chance, diese zu erreichen.
        </p>
        <p>
          Zum Beispiel, vor einem Publikum zu zeigen, was man gemeinsam mit
          anderen erlernt hat. <b>Mit einer eigenen Band!</b> Willst du bei
          diesem Projekt dabei sein? Möchtest du Banderfahrung sammeln?
        </p>
        <p>
          Dann melde dich schnell bei uns im Büro oder nutze unser
          Online-Formular für deine Anmeldung. Wir bilden auf Anfrage Bands in{" "}
          <b>JEDEM Alter!</b> :-) Wir freuen uns auf euch!
        </p>
      </Section>
      <Section>
        <BandBlock img={Bonmuto} name="Bonmuto">
          Speed-Dating mit 100% igem Erfolg, dass ist BoNMuTo, eine
          "Schüler"band, frei zusammengewürfelt aus Musikschülerinnen und
          -schülern der Musikschule "Musik-Total" in Gelnhausen. Auch wenn wir
          noch volle Pulle in den "Kinderschuhen" stecken und keiner von uns
          weiß, wie lange es uns gibt und was aus uns wird, alleine, dass wir
          uns in dieser Konstellation getroffen und sofort so gut verstanden
          haben, macht diese Band so besonders und für uns einzigartig! Ein Hoch
          auf die Musik!
        </BandBlock>
        <BandBlock img={Lunaticmoon} name="Lunatic Moon" reverse>
          Die Schülerband der Musikschule „MUSIK TOTAL“ Gelnhausen-Höchst, ist
          die jüngste bestehende Band der insgesamt sechs Schüler- und
          Erwachsenenbands von Musik Total. Gecoacht wird die Band von Felix
          Rogge, der ebenso als Gitarrenlehrer bei Musik Total das Dozententeam
          verstärkt. Der Auftritt in Bad Orb, ist der dritte Auftritt der jungen
          Musiker und Sängerin, die sich erst seit 5 Monaten zum Proben treffen.
          Mit großer Spielfreude und hochmotiviert, gehen sie auf die Bühne, um
          mit ihrer Musik zu begeistern.
        </BandBlock>
        <BandBlock img={LeavingFootprints} name="LEAVING FOOTPRINTS">
          LEAVING FOOTPRINTS - das sind Sängerin Franziska Walter, Gitarrist
          Robin Pfaff, Keyboarder Fabian Rode, Bassist Adam Sinsel und
          Schlagzeuger Lennard Franz. Der Name ist Programm bei der jungen
          Newcomer-Band aus Gelnhausen! Mit aktuellen Hits und zeitlosen
          Klassikern aus Pop, Rock und Soul hinterlassen die fünf Musiker einen
          bleibenden Eindruck bei ihren Zuhörern.
        </BandBlock>
      </Section>
      <CTA
        text="Rockstar?!"
        theme="primary"
        button={
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconTaperecorder theme="primary" size="1.5rem" />
            <span style={{ margin: "0 .5rem" }}>Dann hier Anmelden!</span>
          </div>
        }
      />
      <SocialMediaBar />
      <Footer />
    </div>
  );
};

export default BandCoaching;
