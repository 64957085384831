import React from "react";
import styled from "styled-components";
import Avatar from "./../../atoms/Avatar/Avatar";
import { variables } from "../../../data/variables";
import LehrerDetails from "./LehrerDetails";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  text-align: center;
  cursor: pointer;
  :hover {
    filter: brightness(120%);
    color: #555;
  }
`;

const Name = styled.div`
  font-weight: bold;
  margin: 1rem 0 0.5rem 0;
`;

const Instruments = styled.div``;

class LehrerBlock extends React.Component {
  state = { visible: false };
  render() {
    const { image, name, instruments, vita, link } = this.props;
    return (
      <>
        <LehrerDetails
          visible={this.state.visible}
          close={() => this.setState({ visible: false })}
          {...this.props}
        />
        <Wrapper onClick={() => this.setState({ visible: true })}>
          <Avatar size={10} image={image} />
          <Name>{name}</Name>
          <Instruments>{instruments}</Instruments>
        </Wrapper>
      </>
    );
  }
}

export default LehrerBlock;
