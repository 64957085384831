import React from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import Section from "../../atoms/Section/Section";

import Italien from "../../../static/img/rainbow/italian.jpg";
import Russian from "../../../static/img/rainbow/russian.jpg";
import Indonesian from "../../../static/img/rainbow/indonesian.jpg";
import Turkish from "../../../static/img/rainbow/turkish.jpg";
import French from "../../../static/img/rainbow/french.jpg";
import Bulgarian from "../../../static/img/rainbow/bulgarian.jpg";
import Persian from "../../../static/img/rainbow/persian.jpg";
import English from "../../../static/img/rainbow/english.jpg";
import German1 from "../../../static/img/rainbow/german1.jpg";
import German2 from "../../../static/img/rainbow/german2.jpg";
import Icelandic from "../../../static/img/rainbow/icelandic.jpg";
import Chinese from "../../../static/img/rainbow/chinese.jpg";
import Arabic from "../../../static/img/rainbow/arabic.jpg";
import Swedish from "../../../static/img/rainbow/swedish.jpg";
import Philip from "../../../static/img/rainbow/philippinian.jpg";
import Macedon from "../../../static/img/rainbow/mazedonian.jpg";

import FranceFlag from "../../../static/img/flags/france.svg";
import ItalyFlag from "../../../static/img/flags/italy.svg";
import RussianFlag from "../../../static/img/flags/russia.svg";
import BulgarianFlag from "../../../static/img/flags/bulgaria.svg";
import GermanFlag from "../../../static/img/flags/germany.svg";
import SwedenFlag from "../../../static/img/flags/sweden.svg";
import IcelandFlag from "../../../static/img/flags/iceland.svg";
import ChinaFlag from "../../../static/img/flags/china.svg";
import AfghanistanFlag from "../../../static/img/flags/afghanistan.svg";
import IndoFlag from "../../../static/img/flags/indonesia.svg";
import IranFlag from "../../../static/img/flags/iran.svg";
import TurkeyFlag from "../../../static/img/flags/turkey.svg";
import UKFlag from "../../../static/img/flags/united-kingdom.svg";
import MacFlag from "../../../static/img/flags/republic-of-macedonia.svg";
import PhilFlag from "../../../static/img/flags/philippines.svg";
import { Heading } from "../../atoms/Hero/Hero";

const sheetData = [
  { lang: "English", img: English, flag: UKFlag },
  { lang: "German", img: German1, flag: GermanFlag },
  { img: German2 },
  { lang: "Russian", img: Russian, flag: RussianFlag },
  { lang: "Indonesian", img: Indonesian, flag: IndoFlag },
  { lang: "Swedish", img: Swedish, flag: SwedenFlag },
  { lang: "Bulgarian", img: Bulgarian, flag: BulgarianFlag },
  { lang: "Persian", img: Persian, flag: IranFlag },
  { lang: "French", img: French, flag: FranceFlag },
  { lang: "Italian", img: Italien, flag: ItalyFlag },
  { lang: "Turkish", img: Turkish, flag: TurkeyFlag },
  { lang: "Icelandic", img: Icelandic, flag: IcelandFlag },
  { lang: "Chinese", img: Chinese, flag: ChinaFlag },
  { lang: "Arabic", img: Arabic, flag: AfghanistanFlag },
  { lang: "Macedonian", img: Macedon, flag: MacFlag },
  { lang: "Philippinian", img: Philip, flag: PhilFlag },
];

const sheetImgs = sheetData.map((s) => s.img);

export const Sheets = () => {
  const [sheets, setSheets] = React.useState({ open: false, index: 0 });

  return (
    <>
      {sheets.open && (
        <Lightbox
          mainSrc={sheetImgs[sheets.index]}
          nextSrc={sheetImgs[(sheets.index + 1) % sheetData.length]}
          prevSrc={
            sheetImgs[(sheets.index + sheetData.length - 1) % sheetData.length]
          }
          onCloseRequest={() => setSheets({ ...sheets, open: false })}
          onMovePrevRequest={() =>
            setSheets({
              ...sheets,
              index: (sheets.index + sheetData.length - 1) % sheetData.length,
            })
          }
          onMoveNextRequest={() =>
            setSheets({
              ...sheets,
              index: (sheets.index + 1) % sheetData.length,
            })
          }
        />
      )}
      <Section>
        <Heading>Der World Rainbow Song in deiner Sprache</Heading>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(260px, 1fr))",
            width: "100%",
            marginTop: "2rem",
          }}
        >
          {sheetData.map((s, i) => {
            if (!s.lang) return <></>;
            return (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  margin: "1rem 0",
                  cursor: "pointer",
                }}
                onClick={() =>
                  setSheets({
                    index: i,
                    open: true,
                  })
                }
              >
                <img
                  style={{ height: "3rem", marginRight: "2rem" }}
                  src={s.flag}
                />
                <span style={{ fontSize: "2rem", fontWeight: "bold" }}>
                  {s.lang}
                </span>
              </div>
            );
          })}
        </div>
      </Section>
    </>
  );
};
