import * as React from 'react';
import { Icon } from '.';

export const IconAcoustic: React.SFC<{}> = props => {
  return (
    <Icon
      d="M47.34,6.07C47.74,5.67,48,5.13,48,4.109C48,1.46,46.58,0,44,0c-1.08,0-1.62,0.27-2.06,0.721 c-0.051-0.16-0.12-0.311-0.23-0.431c-0.38-0.37-1.05-0.37-1.42,0C40.11,0.48,40,0.74,40,1c0,0.27,0.11,0.52,0.29,0.71 c0.16,0.16,0.38,0.24,0.61,0.271c-0.23,0.24-0.53,0.52-0.94,0.819c-0.04-0.19-0.12-0.37-0.25-0.51c-0.38-0.37-1.06-0.36-1.42,0 C38.11,2.48,38,2.73,38,3c0,0.26,0.11,0.52,0.29,0.71c0.09,0.09,0.21,0.16,0.33,0.21c-0.22,0.23-0.45,0.49-0.68,0.78 c-0.051-0.15-0.12-0.3-0.23-0.41c-0.37-0.37-1.05-0.37-1.42,0C36.11,4.48,36,4.74,36,5c0,0.27,0.11,0.52,0.29,0.71 C36.48,5.891,36.74,6,37,6c0.05,0,0.09-0.02,0.13-0.029c-0.18,0.389-0.32,0.789-0.36,1.17c-0.02,0.239,0,0.449,0.04,0.639 L24.71,19.88C22.98,18.71,21,18,19,18c-5.21,0-6.01,3.46-6.44,5.311c-0.08,0.369-0.2,0.879-0.27,0.979 c-0.37,0.38-1.73,0.63-2.93,0.851C5.63,25.82,0,26.85,0,33.98C0,40.529,7.47,48,14.02,48c7.13,0,8.16-5.63,8.84-9.359 c0.22-1.201,0.47-2.561,0.83-2.91c0.12-0.09,0.63-0.211,1-0.291C26.54,35.01,30,34.21,30,29c0-2-0.71-3.98-1.88-5.71l12.1-12.09 c0.12,0.021,0.261,0.04,0.41,0.04c0.07,0,0.15,0,0.23-0.01c0.38-0.041,0.779-0.181,1.17-0.36C42.02,10.91,42,10.95,42,11 c0,0.26,0.11,0.52,0.29,0.71C42.48,11.891,42.74,12,43,12s0.52-0.109,0.71-0.29C43.89,11.52,44,11.26,44,11s-0.11-0.52-0.29-0.71 c-0.11-0.11-0.26-0.181-0.41-0.22c0.29-0.23,0.55-0.461,0.78-0.68C44.13,9.51,44.2,9.62,44.29,9.71C44.48,9.891,44.74,10,45,10 s0.52-0.109,0.71-0.29C45.89,9.52,46,9.26,46,9s-0.11-0.52-0.29-0.71c-0.14-0.14-0.33-0.21-0.52-0.24 c0.31-0.409,0.59-0.71,0.829-0.93c0.03,0.22,0.12,0.43,0.271,0.59C46.48,7.891,46.74,8,47,8s0.52-0.109,0.71-0.29 C47.89,7.52,48,7.26,48,7s-0.11-0.52-0.29-0.71C47.6,6.189,47.47,6.12,47.34,6.07z M13.29,40.71l-6-6l1.42-1.42l6,6L13.29,40.71z M18,34c-2.21,0-4-1.79-4-4s1.79-4,4-4s4,1.79,4,4S20.21,34,18,34z"
      {...props}
    />
  );
};
