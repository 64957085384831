import * as React from "react";
import { variables } from "../../../data/variables";

export const Icon = ({
  theme = "secondary",
  size = "48px",
  transition = "",
  className = "",
  d = ""
}) => {
  const style = transition ? { style: { transition } } : {};
  return (
    <div
      style={{
        width: size,
        height: size
      }}
    >
      <svg
        viewBox="0 0 48 48"
        width={size}
        height={size}
        fill={variables.text[theme]}
        className={className}
        {...style}
      >
        <defs />
        <path id="a" d={d} />
      </svg>
    </div>
  );
};

export default Icon;
