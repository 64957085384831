import React from "react";
import Hero from "../../atoms/Hero/Hero";
import Section from "../../atoms/Section/Section";
import SocialMediaBar from "../../molecules/SocialMediaBar/SocialMediaBar";
import Footer from "../../organisms/Footer/Footer";
import { Workshop } from "../../molecules/Workshop/Workshop";
import { useParams } from "react-router-dom";
import {
  fetchById,
  fetchNewsSingle,
  structureNewsData,
} from "../../../helper/fetchContent";
import PicTextBlock from "../../molecules/PicTextBlock/PicTextBlock";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import PolaroidWrapper from "../../atoms/PolaroidWrapper/PolaroidWrapper";
import styled from "styled-components";

const Picture = styled.img`
  width: 100%;
  max-height: 30rem;
  object-fit: cover;
  overflow: hidden;
  border: 1rem solid white;
  margin-bottom: 1rem;
  margin-top: -2rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  flex: 1;
`;

const NewsSingle = () => {
  let { id } = useParams();
  const [newsData, setNews] = React.useState([]);
  React.useEffect(() => {
    const f = async () => {
      const d = await fetchNewsSingle(id);

      setNews(d[0]);
    };
    f();
  }, []);
  return (
    <div>
      <Hero small>{newsData ? newsData.title : "Loading..."}</Hero>
      <Section theme="white" style={{ paddingTop: 0 }} id="newsEntry">
        {newsData && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Picture src={newsData.img} />
            </div>

            {documentToReactComponents(newsData.inhalt)}
          </>
        )}
      </Section>
      <SocialMediaBar />
      <Footer />
    </div>
  );
};

export default NewsSingle;
