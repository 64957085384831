import * as React from 'react';
import { Icon } from '.';

export const IconNote1: React.SFC<{}> = props => {
  return (
    <Icon
      d="M37,17.5c0,3.814-1.71,6.042-4.077,9.125c-0.355,0.463-0.73,0.953-1.123,1.475l-1.694-1.045 c0.019-0.039,1.893-3.831,1.895-6.555c0-2.26-1.548-2.8-4.072-3.495c-0.968-0.267-2.039-0.562-2.935-1.077L24.98,36.08 C24.992,36.227,25,36.369,25,36.5c0,1.768-1.113,3.371-3.135,4.514c-1.646,0.932-3.809,1.486-5.787,1.486h-0.001h-0.085 c-1.776-0.014-3.168-0.492-4.026-1.381c-0.659-0.684-0.993-1.598-0.964-2.646c0.096-3.572,4.709-5.973,8.956-5.973 c1.319,0.006,2.296,0.238,3.024,0.596L23,5.5h2c0,1.623,1.072,2.056,3.909,2.794C32.323,9.183,37,10.4,37,17.5z"
      {...props}
    />
  );
};
