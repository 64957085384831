import * as React from 'react';
import { Icon } from '.';

export const IconSpeaker: React.SFC<{}> = props => {
  return (
    <Icon
      d="M24,10c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S25.1,10,24,10z M24,10c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S25.1,10,24,10z M24,10c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S25.1,10,24,10z M24,10c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S25.1,10,24,10z M24,10 c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S25.1,10,24,10z M24,10c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S25.1,10,24,10z M24,10 c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S25.1,10,24,10z M24,10c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S25.1,10,24,10z M24,10 c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S25.1,10,24,10z M24,10c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S25.1,10,24,10z M24,10 c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S25.1,10,24,10z M24,10c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S25.1,10,24,10z M24,10 c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S25.1,10,24,10z M24,10c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S25.1,10,24,10z M24,10 c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S25.1,10,24,10z M24,10c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S25.1,10,24,10z M24,10 c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S25.1,10,24,10z M8,0v46h2v2h5v-2h18v2h5v-2h2V0H8z M24,5c3.86,0,7,3.14,7,7s-3.14,7-7,7 s-7-3.14-7-7S20.14,5,24,5z M12,43c-0.55,0-1-0.45-1-1s0.45-1,1-1s1,0.45,1,1S12.55,43,12,43z M12,5c-0.55,0-1-0.45-1-1s0.45-1,1-1 s1,0.45,1,1S12.55,5,12,5z M24,41c-5.51,0-10-4.49-10-10s4.49-10,10-10s10,4.49,10,10S29.51,41,24,41z M36,43c-0.55,0-1-0.45-1-1 s0.45-1,1-1s1,0.45,1,1S36.55,43,36,43z M36,5c-0.55,0-1-0.45-1-1s0.45-1,1-1s1,0.45,1,1S36.55,5,36,5z M28,31c0,2.21-1.79,4-4,4 s-4-1.79-4-4s1.79-4,4-4S28,28.79,28,31z" 
      {...props}
    />
  );
};
