import React from "react";
import styled from "styled-components";
import { variables } from "../../../data/variables";
import PolaroidWrapper from "./../../atoms/PolaroidWrapper/PolaroidWrapper";

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem 0;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  @media (min-width: ${variables.breakpoints.m}px) {
    ${(props) =>
      props.reverse ? "flex-direction:row-reverse;" : "flex-direction:row;"}
  }
`;

const Picture = styled.img`
  width: 100%;
  max-height: 20rem;
  object-fit: cover;
  overflow: hidden;
  flex: 1;
`;

const Description = styled.div``;

const Content = styled.div`
  padding: 2rem 0;
  @media (min-width: ${variables.breakpoints.m}px) {
    padding: 2rem;
    ${(props) => props.reverse && "text-align:right;"}
  }
  flex: 1;
`;

const Title = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
`;

const PicTextBlock = (props) => {
  return (
    <Container reverse={props.reverse}>
      <PolaroidWrapper>
        <Picture src={props.img} />
      </PolaroidWrapper>
      <Content reverse={props.reverse}>
        {props.title && <Title>{props.title}</Title>}
        <Description>{props.children}</Description>
      </Content>
    </Container>
  );
};

export default PicTextBlock;
