import React from "react";
import Section from "../../atoms/Section/Section";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { variables } from "../../../data/variables";
const FooterSection = styled(Section)``;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Info = styled.div`
  flex: 1;
  font-size: 0.8rem;
  padding: 0 0.5rem 1rem 0.5rem;
`;

const Column = styled.div`
  display: inline-flex;
  min-width: 10rem;
  flex-direction: column;
  padding: 0 0.5rem 1rem 0.5rem;
  flex: 1;

  h1 {
    font-size: 1rem;
    margin: 0;
    margin-bottom: 0.2rem;
  }

  ul {
    margin: 0;
    padding: 0;
    li {
      list-style-type: none;
      font-size: 0.8rem;
      a {
        text-decoration: none;
        color: unset;

        :hover {
          filter: brightness(120%);
        }
      }
    }
  }
`;

const Footer = () => {
  return (
    <FooterSection theme="secondary">
      <Content>
        <Column>
          <h1>Musik Total</h1>
          <ul>
            <li>
              <Link to="/impressum">Impressum</Link>
            </li>
            {/* <li>
              <Link to="/">Datenschutzerklärung</Link>
            </li> */}
            <li>
              <Link to="/infos-und-gebühren">Gebühren</Link>
            </li>
          </ul>
        </Column>
        {/* <Column> */}
        {/* <h1>Unsere Dozenten</h1>
          <ul>
            <li>
              <a target="_blank" href="http://thomas-bopp.de">
                Thomas Bopp
              </a>
            </li>
            <li>
              <a target="_blank" href="http://silke-knoll.de">
                Silke Knoll
              </a>
            </li>
            <li>
              <a target="_blank" href="http://felixrogge.de/">
                Felix Rogge
              </a>
            </li>
          </ul> */}
        {/* </Column> */}
        <Column>
          <h1>Partner & Freunde</h1>
          <ul>
            <li>
              <a target="_blank" href="http://musikverein-hoechst.de/">
                Musikverein Höchst
              </a>
            </li>
            <li>
              <a target="_blank" href="https://tonarts-studio.de/">
                TonArts
              </a>
            </li>
            <li>
              <a target="_blank" href="http://www.janina-kempel.de/">
                Janina Kempel - Grafik Design
              </a>
            </li>
          </ul>
        </Column>
        <Info>
          Andreas Knoll
          <br />
          Bachstrasse 11 <br />
          63571 Gelnhausen <br />
          06051/75108
          <br />
          info@musik-total.net
          <br />
        </Info>
      </Content>
    </FooterSection>
  );
};

export default Footer;
