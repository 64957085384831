import React from "react";
import styled from "styled-components";
import { variables } from "../../../data/variables";

const AvatarDiv = styled.div`
  height: ${props => props.size + "rem"};
  width: ${props => props.size + "rem"};
  background-color: rgba(0, 0, 0, 0.2);
  background-image: url("${props => props.image}");
  background-position: center 0;
  background-size: cover;
  border-radius: 50%;
`;

const Avatar = props => {
  const { size, image } = props;
  return <AvatarDiv size={size} image={image} />;
};

export default Avatar;
