// import React from "react";
// import Section from "./../../atoms/Section/Section";
// import styled from "styled-components";
// import { variables } from "../../../data/variables";
// import Button from "./../../atoms/Button/Button";
// import { Link } from "react-router-dom";

// const Wrapper = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   @media (max-width: ${variables.breakpoints.s}px) {
//     flex-direction: column;
//     text-align: center;
//   }
// `;
// const Text = styled.h1`
//   margin: 1rem;
//   font-size: 2rem;
// `;
// const Desc = styled.p`
//   margin: 1rem;
//   @media (max-width: ${variables.breakpoints.s}px) {
//     margin: 1rem 1rem 2rem 1rem;
//   }
// `;

// const CTA = (props) => {
//   const { theme = "white" } = props;
//   return (
//     <Section
//       theme={theme}
//       style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
//     >
//       <Wrapper>
//         <Text>{props.text} </Text>
//         <Desc>{props.desc}</Desc>
//         <a
//           href={
//             props.to ||
//             "https://www.axinio.app/booking/forms/trial/53026260-0d3a-490f-a77a-c797b7376889/"
//           }
//         >
//           <Button theme={theme}>{props.button}</Button>
//         </a>
//       </Wrapper>
//     </Section>
//   );
// };

// export default CTA;

import React from "react";
import Section from "./../../atoms/Section/Section";
import styled from "styled-components";
import { variables } from "../../../data/variables";
import Button from "./../../atoms/Button/Button";
import { Link } from "react-router-dom";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: ${variables.breakpoints.m}px) {
    flex-direction: row;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 3rem;
  flex-direction: column;
  text-align: center;
`;

const Wrapper2 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 3rem;

  flex-direction: column;
  text-align: center;
  margin-top: 3rem;
  @media (min-width: ${variables.breakpoints.m}px) {
    margin-top: 0;
  }
`;
const Text = styled.h1`
  margin: 1rem;
  font-size: 2rem;
`;
const Desc = styled.p`
  margin: 1rem 1rem 2rem 1rem;
`;

const CTA = (props) => {
  const { theme = "white" } = props;
  return (
    <Container>
      <Wrapper>
        <Text>{props.text} </Text>
        <Desc>
          {props.desc ||
            "Melde dich jetzt zur Probestunde an und finde dein Instrument!"}
        </Desc>
        <a
          href={
            props.to ||
            "https://www.axinio.app/booking/forms/trial/53026260-0d3a-490f-a77a-c797b7376889/"
          }
        >
          <Button theme={theme}>{props.button}</Button>
        </a>
      </Wrapper>
      <div style={{ width: 1, background: "#ddd", margin: "2rem 0" }} />
      <Wrapper2>
        <Text>Kontakt </Text>
        <Desc>...oder nimm persönlich Kontakt zu uns auf!</Desc>
        <a href="mailto:janina@musik-total.net">
          <Button
            theme="primary"
            hollow
            style={{ color: variables.button.background.primary }}
          >
            Kontakt
          </Button>
        </a>
      </Wrapper2>
    </Container>
  );
};

export default CTA;
