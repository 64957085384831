import * as React from 'react';
import { Icon } from '.';

export const IconGuitar:  React.SFC<{}> = props => {
  return (
    <Icon
      d="M44.939,0c-1.219,0-2.189,0.39-2.969,0.89C41.84,0.43,41.58,0,41.05,0c-1.18,0-0.46,0.8,0.24,1.39 c-0.17,0.13-0.319,0.271-0.46,0.4c-0.15-0.41-0.4-0.771-0.891-0.771C38.75,1.02,39.49,1.82,40.2,2.4 c-0.12,0.119-0.25,0.25-0.38,0.38c-0.141-0.41-0.4-0.761-0.881-0.761C37.75,2.02,38.49,2.82,39.2,3.4 c-0.11,0.109-0.22,0.22-0.341,0.34C38.721,3.35,38.46,3.02,38,3.02c-1.17,0-0.471,0.771,0.221,1.36C38.1,4.5,37.98,4.62,37.859,4.73 C37.721,4.34,37.46,4.02,37,4.02c-1.17,0-0.48,0.771,0.221,1.36c-0.131,0.12-0.25,0.24-0.361,0.351C36.71,5.34,36.46,5.02,36,5.02 c-1.17,0-0.48,0.771,0.21,1.351L36,6.58V7c0,1.27-0.48,2-0.641,2.21L18.88,25.7C18.48,25.35,18,24.77,18,24 c0-0.73,0.79-1.65,1.32-2.26c0.58-0.66,1.07-1.24,0.81-1.96c-0.07-0.2-0.3-0.66-0.97-0.771c-4.63-0.77-5.97,2.59-6.94,5.05 c-0.52,1.301-1.01,2.53-1.82,3.141c-1.25,0.939-2.76,1.58-4.22,2.2C3.14,30.69,0,32.03,0,36c0,5.45,6.76,12,11,12 c4.86,0,6.16-3.23,7.2-5.83c0.76-1.9,1.42-3.54,3.17-4.24c4.54-1.819,5.351-4.38,5.11-5.609C26.33,31.53,25.74,31,25,31 c-0.73,0-1.19,0.32-1.52,0.56C23.14,31.79,22.85,32,22,32c-0.35,0-0.82-0.05-1.01-0.31c-0.21-0.29-0.16-0.83-0.09-1.181l17.78-17.79 l0.03-0.01c0.62-0.63,1.17-0.67,2.97-0.69h0.34c1.19-0.02,1.5-1.34,1.891-3.01c0.199-0.84,0.67-2.8,1.09-2.99c1.65,0,3-1.35,3-3 C48,1.36,46.65,0,44.939,0z M6.29,37.71l1.42-1.42l4,4l-1.42,1.42L6.29,37.71z M12,43c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1 S11.45,43,12,43z M13,42c-0.55,0-1-0.45-1-1s0.45-1,1-1s1,0.45,1,1S13.55,42,13,42z M13.29,37.71l-3-3l1.42-1.42l3,3L13.29,37.71z M15,38c0-0.55,0.45-1,1-1s1,0.45,1,1s-0.45,1-1,1S15,38.55,15,38z M16.29,34.71l-3-3l1.42-1.42l3,3L16.29,34.71z" 
      {...props}
    />
  );
};
