import React from "react";
import Hero from "../../atoms/Hero/Hero";
import Section from "../../atoms/Section/Section";
import SocialMediaBar from "../../molecules/SocialMediaBar/SocialMediaBar";
import Footer from "../../organisms/Footer/Footer";
import CTA from "../../molecules/CTA/CTA";
import GesangsUnterrichtImg from "../../../static/img/gesangsunterricht.jpg";
import GesangsUnterrichtImg2 from "../../../static/img/gesangsunterricht2.jpg";
import PicTextBlock from "./../../molecules/PicTextBlock/PicTextBlock";
import { IconMic } from "./../../atoms/Icon/IconMic";

const Gesangsunterricht = () => {
  return (
    <div>
      <Hero image={GesangsUnterrichtImg}>Gesangsunterricht</Hero>
      <Section theme="secondaryLight">
        Deine Stimme zählt! Jeder kann singen lernen. Und Gesang ist Musik
        gewordenes Lebensgefühl.
      </Section>
      <Section>
        <PicTextBlock img={GesangsUnterrichtImg2}>
          <p>
            Unsere Gesangsdozenten sind rund um die Stimmbildung für euch da,
            unterrichten Pop-, Rock- und Jazz- oder klassischen Gesang, zeigen
            euch, wie ihr mit eurer Stimme rau oder sanft, soulig oder hauchig
            klingt.
          </p>
          <p>
            Atem- und Stimmtechnik, ökonomisches, stimmbandschonendes Singen -
            all das könnt ihr bei uns lernen. Und natürlich auch Tipps für eure
            Bühnenperformance.
          </p>
          <p>
            Das Komplettpaket für alle Sänger/innen - und solche die es werden
            wollen. Vom Anfänger bis zum Profi.
          </p>
          <h2>„Ich kann nicht singen?“ – gibt’s nicht!</h2>
        </PicTextBlock>
      </Section>
      <CTA
        text="JETZT Probestunde ausmachen!"
        theme="primary"
        button={
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconMic theme="primary" size="1.5rem" />
            <span style={{ margin: "0 .5rem" }}>Ich möchte singen!</span>
          </div>
        }
      />
      <SocialMediaBar />
      <Footer />
    </div>
  );
};

export default Gesangsunterricht;
