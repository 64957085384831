import * as React from 'react';
import { Icon } from '.';

export const IconMic: React.SFC<{}> = props => {
  return (
    <Icon
      d="M37,20v0.459l-4.19,3.61c-0.216-2.632-3.399-6.46-3.399-6.46l-13.43,10.77l1.5,2.221c0.22,0.32,1.979,3.061,1.289,5.4 c-0.02,0.09-0.049,0.17-0.08,0.25L6.95,46.359L5,44.41l-3.29,3.299l-1.42-1.42L3.59,43l-1.97-1.961L27.55,12H28 C32.35,12,37,15.209,37,20z M28.95,20.539l-10.29,8.25v0.01l0.38,0.551l0.22,0.34C19.48,30,21.15,32.609,20.6,34.91 C20.721,34.97,20.859,35,21,35c0.21,0,0.4-0.061,0.561-0.17c0.049-0.041,0.1-0.08,0.149-0.121L23,33.67V48h6V28.859l1.52-1.22 l0.091-0.19c1.229-2.449-0.82-5.65-1.061-6L28.95,20.539z M38,0c-2.95,0-5.61,1.289-7.44,3.33c0.71-0.211,1.21-0.301,1.28-0.32 c3.09-0.51,6.65,0.64,9.54,3.09C44.27,8.549,46,11.879,46,15v0.1c0,0.029,0,0.08,0.01,0.141c0.01,0.18,0.021,0.42,0.03,0.699 C47.27,14.279,48,12.22,48,10C48,4.49,43.51,0,38,0z M44,14.959c-0.02-2.529-1.48-5.27-3.91-7.34c-2.45-2.07-5.41-3.05-7.93-2.629 C32.13,5,30.2,5.34,28.71,6.319c-0.44,1.08-0.68,2.261-0.7,3.49L27.95,10H28c5.31,0,11,4.02,11,10v0.06l0.5-0.17 c1.49-0.22,2.87-0.78,4.07-1.591c0.55-1.059,0.47-2.379,0.439-2.939C44,15.189,43.99,15.069,44,14.959z"
      {...props}
    />
  );
};
