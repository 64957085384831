import React from "react";
import Section from "./../../atoms/Section/Section";
import styled from "styled-components";
import { variables } from "../../../data/variables";

const ButtonContainer = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem 1.5rem;
  color: ${(props) =>
    props.hollow
      ? variables.text[props.theme]
      : variables.button.text[props.theme]};
  border-radius: 2rem;
  cursor: pointer;
  ${(props) =>
    props.hollow
      ? `border: 2px solid ${variables.button.background[props.theme]}`
      : ""}
   background: ${(props) =>
     props.hollow ? "" : variables.button.background[props.theme]};
  :hover {
    filter: brightness(150%);
    box-shadow: 0px 1px 2px rgba(0,0,0,.3);
  }
`;

const Button = (props) => {
  const { theme = "primary", hollow = false, ...rest } = props;
  return (
    <ButtonContainer theme={theme} hollow={hollow} {...rest}>
      {props.children}
    </ButtonContainer>
  );
};

export default Button;
